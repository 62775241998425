import { useState } from "react";
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom";
import {API} from "../../services/axios"
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { isApplied } from "./components/isApplied";
import { useDispatch, useSelector } from "react-redux";
import JobApplicationModal from "../jobs/JobApplicationModal";
import _ from "lodash";
import { endApplication, startApplication } from "../../store/slices/jobs";


const ApplyButton = (props) => {

    const {jobId, job, hideButton, hideModal} = props;
    const [loading, setLoading] = useState();
    const { auth } = useSelector((state) => state.auth)
    const { applying } = useSelector((state) => state.jobs)
    const { pathname } = useLocation()
    const dispatch= useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleApply = (profile, answers) => {
        
        const myApplications = window.localStorage.getItem("applications");
    
            setLoading(true);
            dispatch(startApplication(jobId))
            API.post(URLS.JOBS.apply(jobId), {"offer": jobId, "user": profile}).then((resp) => {
                setLoading(false);
                dispatch(endApplication(jobId))
                toast.success(t("jobApplicationSuccess"));
                if(myApplications){
                    window.localStorage.setItem("applications", JSON.stringify([...JSON.parse(myApplications), jobId]))
                } else {
                    window.localStorage.setItem("applications", JSON.stringify([jobId]));
                }
                // Now send the responses of the questions, if any.

                if(answers) {
                    Object.keys(answers).map(function(key, index) {
                        const relatedQuestion = _.filter(job.questions, {id: key});
                        if(relatedQuestion){
                            // Post the objects
                            const obj = {
                                "question": relatedQuestion[0]?.question,
                                "response": answers[key],
                                "application": resp?.data?.id,
                            }
                            API.post(URLS.JOBS.ANSWERS.create, obj).then((resp) => {}).catch((error) => {})
                        }
                    })
                }

            }).catch((error) => {
                setLoading(false);
                dispatch(endApplication(jobId))
                toast.error(error?.response?.data?.detail ?? "An error occured, on you may have already applied for this job.");
            })
        }
        
        const handleOpen = (e) => {
            e?.preventDefault()

            if(auth?.user) {
                window?.$('#ModalApplyJobForm-'+job.id).modal('show');
            } else {
                window.localStorage.setItem("redirect", `job/${jobId}/details`)
                navigate(`/login?redirect=${pathname}`)
            }
        }

    return (
        <>
        {!hideModal && <JobApplicationModal onClose={handleApply} job={job} />}
        {!hideButton && <Link className="btn btn-default mr-15" to="#"  onClick={handleOpen} disabled={loading || isApplied(jobId)}>
            {t("textApplyNow")}
            {(loading || applying.includes(jobId)) && <span className="spinner-border spinner-border-sm ml-15" role="status" aria-hidden="true"></span>}
        </Link>}
        </>
        // <Link className="btn btn-default mr-15" href="#" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">{t("textApplyNow")}</Link>
    )
}

export default ApplyButton