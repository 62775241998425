import Swal from "sweetalert2"

const SignupConfirmation = (t) =>{
    Swal.fire({
        title: t("textSignupSuccess"),
        text: t("textSignupSuccessMsg"),
        icon: "success",
        showCancelButton: false,
        // showCloseButton: true,
        showLoaderOnConfirm: true,
        timerProgressBar: true,
        confirmButtonColor: "#3085d6"
    }).then((result)=>{
            window.location='/login';
    })
}
export { SignupConfirmation}