import { useParams, useSearchParams } from "react-router-dom";
import ManageEducation from "./ManageEducation";
import ManageIntroduction from "./ManageIntroduction";
import ManageWorkExperience from "./ManageWorkExperience";
import { useEffect, useState } from "react";
import { appScrollTo } from "../../../../components/utils/functions";
import { URLS } from "../../../../services/urls";
import { API } from "../../../../services/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Step, Stepper } from "react-form-stepper";
import ManageCertification from "./ManageCertification";
import ManageLanguages from "./ManageLanguages";
import ManageReferences from "./ManageReferences";
import _ from "lodash";
import { PiIdentificationCardLight, PiCertificateLight } from "react-icons/pi";
import { IoSchoolOutline, IoTimeOutline, IoLanguageOutline } from "react-icons/io5";
import { BsPersonCheck } from "react-icons/bs"

const ManageResume = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [resume, setResume] = useState();
  const [step, setStep] = useState(0);
  const [refetch, setRefetch] = useState();
  const { action } = useParams();

  const handleNextStep = () => {
    if (step < 5) {
      setStep((prev) => prev + 1);
    }
  };

  const handleRefetch = () => {
    API.get(URLS.USERS.PROFILE.details(action))
        .then((resp) => {
          const { data } = resp;
          setResume(data);
        })
        .catch((error) => {
        });
  };

  const handlePrevStep = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    const section = searchParams.get("section");
    if (section) {
      appScrollTo(section);
    }
    if (action != "create") {
      // This means it is an edition, and the action attribute is the id of the concerned resume
      setLoading(true);
      API.get(URLS.USERS.PROFILE.details(action))
        .then((resp) => {
          const { data } = resp;
          setResume(data);
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
      setStep(parseInt(searchParams.get("step") ?? 0));
    } else {
      setLoading(false);
    }
  }, [refetch]);
  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("myResume")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <a className="icon-home" href="/">
                  {t("menuHome")}
                </a>
              </li>
              <li>
                <span>{t("myResume")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <Stepper activeStep={step}>
                <Step
                  label={<strong>{t("textIntroduction")}</strong>}
                  onClick={() => setStep(0)}
                  children={
                    <span className="d-flex">
                      <PiIdentificationCardLight style={{ margin: "auto" }} />
                    </span>
                  }
                />
                <Step
                  label={<strong>{t("titleEducation")}</strong>}
                  onClick={() => setStep(1)}
                  children={
                    <span className="d-flex">
                      <IoSchoolOutline style={{ margin: "auto" }} />
                    </span>
                  }
                />
                <Step
                  label={<strong>{t("workExperience")}</strong>}
                  onClick={() => setStep(2)}
                  children={
                    <span className="d-flex">
                      <IoTimeOutline style={{ margin: "auto" }} />
                    </span>
                  }
                />
                <Step
                  label={<strong>{t("textCertifications")}</strong>}
                  onClick={() => setStep(3)}
                  children={
                    <span className="d-flex">
                      <PiCertificateLight style={{ margin: "auto" }} />
                    </span>
                  }
                />
                <Step
                  label={<strong>{t("textLanguages")}</strong>}
                  onClick={() => setStep(4)}
                  children={
                    <span className="d-flex">
                      <IoLanguageOutline style={{ margin: "auto" }} />
                    </span>
                  }
                />
                <Step
                  label={<strong>{t("textReferences")}</strong>}
                  onClick={() => setStep(5)}
                  children={
                    <span className="d-flex">
                      <BsPersonCheck style={{ margin: "auto" }} />
                    </span>
                  }
                />
              </Stepper>
              <div className="d-flex mb-20">
                {loading && (
                  <div
                    className="spinner-border m-auto spinner-border-md"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
              {step === 0 && (
                <ManageIntroduction
                  resume={resume}
                  nextStep={handleNextStep}
                  handleRefetch={handleRefetch}
                />
              )}
              {step === 1 && (
                <ManageEducation
                  resume={resume}
                  handleNext={handleNextStep}
                  handlePrev={handlePrevStep}
                  handleRefetch={handleRefetch}
                />
              )}
              {step === 2 && (
                <ManageWorkExperience
                  resume={resume}
                  handleNext={handleNextStep}
                  handlePrev={handlePrevStep}
                  handleRefetch={handleRefetch}
                />
              )}
              {step === 3 && (
                <ManageCertification
                  resume={resume}
                  handleNext={handleNextStep}
                  handlePrev={handlePrevStep}
                  handleRefetch={handleRefetch}
                />
              )}
              {step === 4 && (
                <ManageLanguages
                  resume={resume}
                  handleNext={handleNextStep}
                  handlePrev={handlePrevStep}
                  handleRefetch={handleRefetch}
                />
              )}
              {step === 5 && (
                <ManageReferences
                  resume={resume}
                  handleNext={handleNextStep}
                  handlePrev={handlePrevStep}
                  handleRefetch={handleRefetch}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageResume;
