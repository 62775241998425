import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  applying: []
}

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    startApplication: (state, action) => {
      state.applying = [...state.applying, action.payload]
    },
    endApplication: (state, action) => {
        state.applying = state.applying.filter((i) => i != action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { startApplication , endApplication} = jobsSlice.actions

export default jobsSlice.reducer