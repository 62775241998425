import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import HomeLayout from "../components/Layouts/Home";
import HomePage from "../pages/home";
import NotFound from "../pages/notfound/NotFound";
import Login from "../pages/login";
import Register from "../pages/register";
import JobSearch from "../pages/jobSearch";
import JobDetails from "../pages/jobDetails";
import UserDashboardLayout from "../components/Layouts/UserDashboard";
import UserDashboard from "../pages/userDashboard/pages";
import MyApplications from "../pages/userDashboard/pages/myApplications";
import MyApplicationDetails from "../pages/userDashboard/pages/myApplications/details";
import MyProfile from "../pages/userDashboard/pages/myProfile";
import MyResumes from "../pages/userDashboard/pages/myResumes";
import ManageResume from "../pages/userDashboard/pages/myResumes/manageResume";
import EmailVerification from "../pages/register/EmailVerification";
import RecoverPassword from "../pages/recoverPassword/RecoverPassword";
import ConfirmRecover from "../pages/recoverPassword/Confirm";
import AboutUs from "../pages/aboutus/Index";
//import CominSoon from "../pages/home/CominSoon";

const AppRoutes = () => {

    return (
        <Router>
            <Routes>
                <Route element={<HomeLayout/>}>
                    <Route index path="/" element={<HomePage />} />
                    <Route path="login" element={<Login/>} />
                    <Route path="register" element={<Register/>} />
                    <Route path="user/accounts/confirm-email/:emailKey" element={<EmailVerification/>} />
                    <Route path="search" element={<JobSearch/>} />
                    <Route path="about" element={<AboutUs/>} />
                    <Route path="/password-reset" element={<RecoverPassword/>} />
                    <Route path="/password-reset/confirm/:uuid/:token" element={<ConfirmRecover/>} />
                    <Route path="job/:jobId/details" element={<JobDetails/>} />
                </Route>
                <Route path="dashboard" element={<UserDashboardLayout/>}>
                    <Route index path="" element={<UserDashboard />} />
                    <Route path="my-applications" element={<MyApplications/>} />
                    <Route path="my-applications/:applicationId/details" element={<MyApplicationDetails/>} />
                    <Route path="profile" element={<MyProfile/>} />
                    <Route path="my-resumes" element={<MyResumes/>} />
                    <Route path="my-resumes/manage/:action" element={<ManageResume/>} />
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    )
}

export default AppRoutes;

//glpat-rJzcbzWSBmRUCfPUysfh