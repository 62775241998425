import { useTranslation } from "react-i18next";
import TimelineCard from "./components/TimelineCard";
import { URLS } from "../../../../services/urls";
import { useEffect, useRef, useState } from "react";
import { styles } from "../../../../components/utils/reactSelect";
import { API } from "../../../../services/axios";
import { useSelector } from "react-redux";
import useSWR from "swr";
import CreatableSelect from "react-select/creatable";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmDeletion } from "../../../../components/dletion";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import CertificationCard from "./components/CertificationCard";
import { ErrorMessage } from "@hookform/error-message";

const ManageCertification = (props) => {
  const {resume, handleNext, handlePrev, handleRefetch} = props;
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [certifications, setCertifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toEdit, setToEdit] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState();
  const { action } = useParams();

  const editorRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm({});

  const formRef = useRef(null)

  const handleChangeCV = (event) => {
    console.log(event.target.files)
    const file = event.target.files[0];
    setFile(file)
  }

  const handleDeleteCV = (event) => {
    event.preventDefault();
    setFile()
  }

  const handleChangeEducation = (certification) => {
    setToEdit(certification);
    reset({
      designation: certification?.designation,
      date: certification?.end_date,
    });
    setFile({name: certification.file})
    editorRef?.current?.setContent(certification.description)
    formRef?.current?.scrollIntoView()
  }

  const handleDeleteEducation = (certification) => {

    const onSuccess = () => {
        setCertifications((prev) => {
        return prev.filter(item => item.id != certification.id)
      })
    }
    confirmDeletion(URLS.USERS.PROFILE.CERTIFICATION.update(certification.id), t, onSuccess)
  }

  const onSubmit = (data) => {
    console.log(selected);
    setLoading(true);
    let formData = new FormData();
    formData.append("designation", data.designation);
    formData.append("date", data.date);
    formData.append("profile", action);

    if (editorRef.current) {
        const description = editorRef.current.getContent();
        formData.append("description", description);
    }
    
    if(file?.type){
        formData.append("file", file);
    }

    if (!toEdit) {
      API.post(URLS.USERS.PROFILE.CERTIFICATION.create, formData)
        .then((resp) => {
          setLoading(false);
          reset();
          editorRef?.current?.setContent("<p></p>")
          toast.success(t("textCertifAdded"));
          setCertifications((prev) => [{...resp.data }, ...prev]);
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail ?? error?.response?.data?.messagerror ?? t("textCertifAddingError"));
          setLoading(false);
        });
    } else {
      API.patch(URLS.USERS.PROFILE.CERTIFICATION.update(toEdit.id), formData)
        .then((resp) => {
          setLoading(false);
          const { data } = resp;

          toast.success(t("educationEdited"));
          setCertifications((prev) => {
            const eds = prev?.map(ed => {
              if(ed.id === toEdit.id) {
                ed.date = data.date;
                ed.description = data.description;
                ed.designation = data.designation;
                ed.file = data.file;
              }
              return ed
            })
            return eds
          });
          setToEdit();
          reset();
          setSelected();
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (resume){
        setCertifications(resume.certifications)
    }
  }, [resume])

  return (
    <div className="panel-white mb-30" id="education">
      <div className="box-padding">
        <h5 className="icon-edu">{t("textCertifications")}</h5>
        <div className="row mt-30">
          <div className="col-lg-9">
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <div className="row">
              <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textCertifName")} {" "} <span className="text-primary">*</span>
                    </label>
                    <input
                      {...register("designation", { required: t("textFormFieldRequired") })}
                      className="form-control"
                      type="text"
                      placeholder={t("textCertifName")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="designation"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textDateObtained")} {" "} <span className="text-primary">*</span>
                    </label>
                    <input
                      {...register("date", {required: t("textFormFieldRequired") })}
                      className="form-control"
                      type="date"
                      placeholder="12 September 2008"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="date"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                <div className="form-group mb-30">
                  <div className="box-upload">
                    <div className="add-file-upload">
                      <input
                        className="fileupload"
                        type="file"
                        name="file"
                        id="uploadCv"
                        onChange={handleChangeCV}
                      />
                    </div>
                    <label htmlFor="uploadCv" className="btn btn-default">
                      {t("uploadFile")} {" "} <span className="">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                {file && <div className="form-group mb-30 box-file-uploaded">
                  <span>{file?.name}</span> <br />
                  <Link className="btn btn-delete" to="#" onClick={handleDeleteCV}>{t("buttonDelete")}</Link>
                </div>}
              </div>
                <div className="col-lg-12">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textDescription")} {" "} <span className="text-primary">*</span>
                    </label>
                    <Editor
                      apiKey="bftuocrw2twv0sruorf4gtbqi7h959bup9opwrqb0pt6oe81"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p></p>"
                      init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-10 d-flex">
                    <button className="btn btn-default btn-brand icon-tick ml-10">
                      {!toEdit ? t("textAddCertification") : t("textEditCertification")}
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm ml-10"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="box-timeline mt-50">
              {
                certifications.map((certification) => (<CertificationCard key={certification.id} handleChange={handleChangeEducation} handleDelete={handleDeleteEducation} certification={certification} />))
              }
            </div>
            <div className="col-lg-12 mt-3">
                  <div className="form-group mt-10 d-flex">
                  <button onClick={handlePrev} className="btn btn-secondary btn-brand line-height-24">
                        {t("textPrevious")}
                    </button>
                    
                    <button onClick={handleNext}  className="btn btn-default btn-brand ml-auto">
                    {t("textNext")}
                    </button>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageCertification;
