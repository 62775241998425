
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { setUserDetails } from "../../components/utils/functions";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/slices/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const NotFound = () => {
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  
  return (
    <div className=" box-content ">
      <div className=" hero-2">
        <div className="banner-inner"></div>
      </div>
      <section class="vh-100">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card rounded border shadow-lg p-4 bg-transparent">
                <div class="card-body  p-5 text-center">
                  <div class="text-center">
                    <Link to={'/'}>
                    <img
                      src="assets/imgs/page/homepage2/logo.png"
                      style={{ width: "185px" }}
                      alt="logo"
                    />
                    </Link>
                  </div>
                    REVENEZ SUR LE SITE EN CLIQUANT SUR LE LOGO
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
