import { useTranslation } from "react-i18next";

const JobProcess = () => {
  const { t } = useTranslation();

  return (
    <section className="section-box bg-15 pt-50 pb-50 mt-80">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center mb-30">
            <img
              className="img-job-search mt-20"
              src="/assets/imgs/page/provided/5.png"
              alt="Cible rh emploie"
            />
          </div>
          <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
            <h2 className="mb-40 text">{t("textOurProcess")}</h2>
            <div className="box-checkbox mb-30">
              <h6>{t("textInscriptionCreateAccount")}</h6>
              <p className="font-md color-text-paragraph-2">
                {t("textInscriptionCreateAccountText")}
              </p>
            </div>
            <div className="box-checkbox mb-30">
              <h6>{t("textSearchJob")}</h6>
              <p className="font-md color-text-paragraph-2">
                {t("textSearchJob")}
              </p>
            </div>
            <div className="box-checkbox mb-30">
              <h6>{t("textHowToApply")}</h6>
              <p className="font-md color-text-paragraph-2">
                {t("textHowToApplyText")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobProcess;
