import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ConfirmRecover = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { t } = useTranslation();
  const { uuid, token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({});
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setLoading(true);

    const toSend = {
      new_password1: data.password,
      new_password2: data.confirmPassword,
      uid: uuid,
      token: token,
    };

    API.post(URLS.AUTHS.PASSWORD.confirm, toSend)
      .then((resp) => {
        setLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.detail ?? error?.response?.data?.messagerror ?? t("textConfirmError"));
      });
  };

  const passwordValidationRules = {
    required: t("textFormFieldRequired"),
    minLength: {
      value: 8,
      message: t("textPwMinLength")
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
      message: t("textPwPattern")
    }
  };

  return (
    <div className="box-content">
      <div className="box-heading"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30 mt-100">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-3">
                      <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                            <p className="font-sm text-brand-2">
                              {t("textSetNewPassword")}{" "}
                            </p>
                          </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-password">
                                {t("password")} *
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  id="input-password"
                                  type={showPassword ? "text" : "password"}
                                  {...register("password", passwordValidationRules)}
                                  placeholder="************"
                                />
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                              </div>
                              <span className="text-primary">
                                {errors.password?.message && (
                                  <small>{errors.password.message}</small>
                                )}
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-confirm-password">
                                {t("confirmPassword")} *
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  id="input-confirm-password"
                                  type={showConfirmPassword ? "text" : "password"}
                                  {...register("confirmPassword", {
                                    required: t("textFormFieldRequired"),
                                    validate: (val) => {
                                      if (watch("password") != val) {
                                        return t("textPwMustMatch");
                                      }
                                    },
                                  })}
                                  placeholder="************"
                                />
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                              </div>
                              <span className="text-primary">
                                {errors.confirmPassword?.message && (
                                  <small>{errors.confirmPassword.message}</small>
                                )}
                              </span>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                name="login"
                                disabled={loading}
                              >
                                {t("textSetNewPassword")}
                                {loading && (
                                  <div
                                    className="spinner-border ml-10 spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="img-2">
                          <img
                            src="/assets/imgs/page/login-register/img-3.svg"
                            alt="Cible rh emploie"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRecover;
