import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import LangSwitcher from "./components/LangSwitcher";
import "./header.css";
import { useState } from "react";

const Header = () => {
  const { user } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const handleChange = (event) => {
    event.preventDefault();
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header
        className={`header sticky-bar ${menuOpen ? "mobile-menu-active" : ""}`}
      >
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link className="d-flex" to="/">
                  <img
                    className="app-logo"
                    alt="CIBLE rh emploie"
                    src="/logo.png"
                  />
                </Link>
              </div>
            </div>
            <div className="header-nav">
              <nav className="nav-main-menu">
                <ul className="main-menu">
                  <li className="">
                    <Link className={pathname === "/" ? "active" : ""} to="/">
                      {t("textHome")}
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className={pathname === "/search" ? "active" : ""}
                      to="/search"
                    >
                      {t("textFindJob")}
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className={pathname === "/about" ? "active" : ""}
                      to="/about"
                    >
                      {t("textAboutUs")}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header-right">
              {user ? (
                <div className="dashboard">
                  <Link to="/dashboard" target="_blank">
                    {t("textDashboard")}
                  </Link>
                </div>
              ) : (
                <div className="block-signin block-signin-with-lang">
                  <LangSwitcher />
                  <Link className="text-link-bd-btom hover-up" to="/register">
                    {t("textRegister")}
                  </Link>
                  <Link
                    className="btn btn-default btn-shadow ml-40 hover-up"
                    to="/login"
                  >
                    {t("textSignIn")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <div
        className={`burger-icon burger-icon-white ${
          menuOpen ? "burger-close" : ""
        }`}
        onClick={toggleMenu}
      >
        <span className="burger-icon-top"></span>
        <span className="burger-icon-mid"></span>
        <span className="burger-icon-bottom"></span>
      </div>
      <div
        className={`mobile-menu-wrapper ${
          menuOpen ? "mobile-menu-visible" : ""
        }`}
      >
        <div className="mobile-menu-close" onClick={toggleMenu}>
          &times;
        </div>
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-search mobile-header-border mb-30">
                <form action="#">
                  <input type="text" placeholder="Search…" />
                  <i className="fi-rr-search"></i>
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                {/* <!-- mobile menu start--> */}
                <nav>
                  <ul className="mobile-menu font-heading">
                    <li className="" onClick={toggleMenu}>
                      <Link className="active" to="/">
                        {t("textHome")}
                      </Link>
                    </li>
                    <li className="" onClick={toggleMenu}>
                      <Link to="/search">{t("textFindJob")}</Link>
                    </li>
                    <li onClick={toggleMenu}>
                      <Link to="/about">{t("textAboutUs")}</Link>
                    </li>

                    <li onClick={toggleMenu}>
                      <Link to="/login">{t("textLogin")}</Link>
                    </li>
                    <li onClick={toggleMenu}>
                      <Link to="/register">{t("textRegister")}</Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="site-copyright">
                Copyright {new Date().getFullYear()} &copy; Cible RH. <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
