import { toast } from "react-toastify"
import { API } from "./axios";
import { URLS } from "./urls";


export const logout = (t) => {

    const id = toast.loading(t("textLoginingOut"));

    API.get(URLS.AUTHS.LOGOUT).then(() => {
        window.localStorage.removeItem("user-details")
        window.location.pathname = "/";
        toast.dismiss(id)
    }).catch((error) => {
        toast.update(id, {render: error?.response?.data?.detail, type: "error", isLoading: false, autoClose:true});
    })
}