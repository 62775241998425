import { useState, useEffect, useRef } from "react";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useSWR from "swr";
import JobCardOld from "../../../components/jobs/JobCard2";
import { URLS } from "../../../services/urls";
import "./home.css";
import { useTranslation } from "react-i18next";

const OldJob = () => {
  const { t } = useTranslation();

  const swiperContainerRef = useRef(null); // useRef pour référencer le conteneur Swiper
  const [swiperInstance, setSwiperInstance] = useState(null); // Stocker l'instance de Swiper

  // État pour les données des jobs et la pagination
  const [page, setPage] = useState("");
  const { data: jobs, isLoading: loadingJobs } = useSWR(URLS.JOBS.search(page));

  // Initialisation de Swiper
  useEffect(() => {
    if (!swiperInstance && swiperContainerRef.current) {
      const swiper = new Swiper(swiperContainerRef.current, {
        modules: [Navigation, Pagination],
        spaceBetween: 30,
        slidesPerView: 6,
        slidesPerGroup: 2,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 10000,
        },
        breakpoints: {
          1360: {
            slidesPerView: 6,
          },
          1199: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 3,
          },
          400: {
            slidesPerView: 2,
          },
          250: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 15,
          },
        },
      });

      setSwiperInstance(swiper); // Sauvegarde de l'instance Swiper
    }

    // Fonction de nettoyage pour détruire l'instance de Swiper
    return () => {
      if (swiperInstance) {
        swiperInstance.destroy(true, true);
        setSwiperInstance(null); // Réinitialisation de l'état de l'instance Swiper
      }
    };
  }, [jobs]); // Dépendance aux jobs pour réinitialiser Swiper si nécessaire

  // Mise à jour de Swiper en cas de changement de jobs sans recréer l'instance
  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update(); // Mise à jour de Swiper pour les nouveaux slides
    }
  }, [jobs, swiperInstance]);

  return (
    <section className="section-box mt-50">
      <div className="container">
        <div className="text-start">
          <h2 className="section-title text mb-10">{t("AllJobs")}</h2>
          <p className="font-lg color-text-paragraph-2">{t("")}</p>
        </div>
        <div className="box-swiper mt-50">
          <div className="swiper-container swiper-group-6 mh-none swiper" ref={swiperContainerRef}>
            <div className="swiper-wrapper pb-70 pt-5">
              {Array.isArray(jobs?.results) && jobs.results.map((job) => (
                <div key={"pop_job" + job.id} className="swiper-slide hover-up">
                  <JobCardOld job={job} />
                </div>
              ))}
            </div>
            <div className="swiper-button-next swiper-button-next-1"></div>
            <div className="swiper-button-prev swiper-button-prev-1"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OldJob;
