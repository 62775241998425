import { useTranslation } from "react-i18next";
import { URLS } from "../../../../services/urls";
import { useEffect, useRef, useState } from "react";
import { styles } from "../../../../components/utils/reactSelect";
import { API } from "../../../../services/axios";
import { useSelector } from "react-redux";
import useSWR from "swr";
import CreatableSelect from "react-select/creatable";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmDeletion } from "../../../../components/dletion";
import ExperienceTimelineCard from "./components/ExperienceTimeLine";
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";
import moment from "moment";

const d = new Date();
const maxDate = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate());

const ManageWorkExperience = (props) => {
  const { resume, handleNext, handlePrev, handleRefetch } = props;
  const { t } = useTranslation();
  const [experiences, setExperiences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSectors, setIsLoadingSectors] = useState(false);
  const [toEdit, setToEdit] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");
  const [searchSectors, setSearchSectors] = useState("");
  const [selected, setSelected] = useState();
  const [selectedSector, setSelectedSector] = useState();
  const { action } = useParams();
  const editorRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({});

  const formRef = useRef(null);
  const { data: companies, isLoading: loadingCompanies } = useSWR(
    URLS.CONFIGS.PROFILE.COMPANY.list(search)
  );

  const { data: sectors, isLoading: loadingSectors } = useSWR(
    URLS.CONFIGS.PROFILE.SECTOR.list(search)
  );

  const handleChangeExperience = (experience) => {
    setToEdit(experience);
    console.log(experience);
    reset({
      educationFrom: experience?.start_date,
      currentlyWorking: experience?.current_job,
      educationTo: experience?.end_date,
      postTitle: experience?.title,
      description: experience?.description,
    });
    setSelected({
      label: experience?.company?.name,
      value: experience?.company?.id,
    });
    setSelectedSector({
      label: experience?.industry?.name,
      value: experience?.industry?.id,
    });
    editorRef?.current?.setContent(experience?.description);
    formRef?.current?.scrollIntoView();
  };
  const handleDeleteExperience = (experience) => {
    const onSuccess = () => {
      setExperiences((prev) => {
        return prev.filter((item) => item.id != experience.id);
      });
    };
    confirmDeletion(
      URLS.USERS.PROFILE.EXPERIENCE.update(experience.id),
      t,
      onSuccess
    );
  };

  const handleCreateCompany = (inputValue) => {
    setIsLoading(true);
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
      company_url: "www.example.com",
    };
    API.post(URLS.CONFIGS.PROFILE.COMPANY.create, data)
      .then((resp) => {
        const { data } = resp;
        setSelected({ label: data.name, value: data.id });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const handleCreateSector = (inputValue) => {
    setIsLoadingSectors(true);
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
    };
    API.post(URLS.CONFIGS.PROFILE.SECTOR.create, data)
      .then((resp) => {
        const { data } = resp;
        setSelectedSector({ label: data.name, value: data.id });
        setIsLoadingSectors(false);
      })
      .catch((error) => {
        setIsLoadingSectors(false);
      });
  };

  const onSubmit = (data) => {
    if (!selected || !selectedSector) {
      toast.error(t("Company or sector is not specified, please fill them in"));
      return;
    }
    console.log(selected);
    setLoading(true);
    let obj = {
      start_date: data.educationFrom,
      end_date: data.currentlyWorking ? null : data.educationTo,
      title: data.postTitle,
      current_job: data.currentlyWorking,
      company: selected?.value,
      activity: selectedSector?.value,
      industry: selectedSector?.value,
      profile: action,
    };

    if (editorRef.current) {
      obj.description = editorRef.current.getContent();
    }

    if (!toEdit) {
      API.post(URLS.USERS.PROFILE.EXPERIENCE.create, obj)
        .then((resp) => {
          setLoading(false);
          reset();
          toast.success(t("experienceAdded"));
          setExperiences((prev) => [
            {
              ...resp.data,
              company: { name: selected.label },
              industry: { name: selectedSector.label },
            },
            ...prev,
          ]);
          setSelected();
          setSelectedSector();
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    } else {
      API.patch(URLS.USERS.PROFILE.EXPERIENCE.update(toEdit.id), obj)
        .then((resp) => {
          setLoading(false);
          const { data } = resp;

          toast.success(t("experienceEdited"));
          setExperiences((prev) => {
            const eds = prev?.map((ed) => {
              if (ed.id === toEdit.id) {
                ed.start_date = data.start_date;
                ed.end_date = data.end_date;
                ed.title = data.title;
                ed.current_job = data.current_job;
                ed.description = data.description;
                ed.company.name = selected?.label;
                ed.industry.name = selected?.label;
              }
              return ed;
            });
            return eds;
          });
          setToEdit();
          reset();
          setSelected();
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (resume) {
      setExperiences(_.reverse(resume.experience));
    }
  }, [resume]);

  return (
    <div className="panel-white mb-30">
      <div className="box-padding">
        <h5 className="icon-edu">{t("workExperience")}</h5>
        <div className="row mt-30">
          <div className="col-lg-9">
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("De")} <span className="text-primary">*</span>
                    </label>
                    <input
                      {...register("educationFrom", {
                        required: t("textFormFieldRequired"),
                      })}
                      className="form-control"
                      type="date"
                      data-date-format="DD MMMM YYYY"
                      lang="fr-CA"
                      placeholder="12 September 2008"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="educationFrom"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("educationTo")} <span className="text-primary">*</span>
                    </label>
                    {!watch("currentlyWorking") && (
                      <input
                        {...register("educationTo")}
                        className="form-control"
                        type="date"
                        max={moment().format("YYYY-MM-DD")}
                        data-date-format="DD MMMM YYYY"
                        placeholder="12 September 2008"
                      />
                    )}
                    <div className="login_footer form-group mt-10 d-flex justify-content-between">
                      <label className="cb-container">
                        <input
                          type="checkbox"
                          {...register("currentlyWorking")}
                        />
                        <span className="text-small">
                          {t("currentlyWorking")}
                        </span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("postTitle")} <span className="text-primary">*</span>
                    </label>
                    <input
                      {...register("postTitle", {
                        required: t("textFormFieldRequired"),
                      })}
                      className="form-control"
                      type="text"
                      placeholder={t("postTitle")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="postTitle"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("Entreprise")} <span className="text-primary">*</span>
                    </label>
                    <CreatableSelect
                      id="company"
                      styles={styles}
                      isSearchable
                      isDisabled={isLoading}
                      inputId="select-company"
                      value={selected}
                      isLoading={isLoading}
                      onInputChange={(value) => setSearch(value)}
                      onCreateOption={handleCreateCompany}
                      onChange={(newValue) => setSelected(newValue)}
                      options={
                        companies?.results?.map((i) => {
                          return { label: i.name, value: i.id };
                        }) ?? []
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("Secteur d'activité")}{" "}
                      <span className="text-primary">*</span>
                    </label>
                    <CreatableSelect
                      id="activity"
                      styles={styles}
                      isSearchable
                      isDisabled={isLoadingSectors}
                      inputId="select-activity"
                      value={selectedSector}
                      isLoading={isLoadingSectors || loadingSectors}
                      onInputChange={(value) => setSearchSectors(value)}
                      onCreateOption={handleCreateSector}
                      onChange={(newValue) => setSelectedSector(newValue)}
                      options={
                        sectors?.results?.map((i) => {
                          return { label: i.name, value: i.id };
                        }) ?? []
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("Description du post")}{" "}
                      <span className="text-primary">*</span>
                    </label>
                    <Editor
                      apiKey="bftuocrw2twv0sruorf4gtbqi7h959bup9opwrqb0pt6oe81"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p></p>"
                      init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-10 d-flex">
                    <button className="btn btn-default btn-brand icon-tick ml-10">
                      {!toEdit
                        ? t("textAddExperience")
                        : t("textEditExperience")}
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm ml-10"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="box-timeline mt-50">
              {experiences.map((experience) => (
                <ExperienceTimelineCard
                  key={experience.id}
                  handleChange={handleChangeExperience}
                  handleDelete={handleDeleteExperience}
                  experience={experience}
                />
              ))}
            </div>
            <div className="col-lg-12 mt-3">
              <div className="form-group mt-10 d-flex">
                {experiences.length >= 1 && (
                  <button
                    onClick={handlePrev}
                    className="btn btn-secondary btn-brand line-height-24"
                  >
                    {t("textPrevious")}
                  </button>
                )}

                {experiences.length >= 1 && (
                  <button
                    onClick={handleNext}
                    className="btn btn-default btn-brand ml-auto"
                  >
                    {t("textNext")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageWorkExperience;
