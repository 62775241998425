export const en = {
  passSpecialChar: "The password must contain at least one special character (e.g., @, #, $, %, &, *).",
  passNumber: "The password must contain at least one number.",
  passLowerCase: "The password must contain at least one lowercase letter.",
  passUpperCase: "The password must contain at least one uppercase letter.",
  textPwMinLength: "At least 8 characters.",
  textPwPattern: "Must contain at least one uppercase letter, one number, and one special character.",
  textcon1: "Terms and Conditions",
  textcon2: "Terms of Use",
  textcon3: " Welcome to our online recruitment site. By accessing and using our site, you agree to comply with and be bound by the following terms and conditions. Please review them carefully. If you do not agree with these terms and conditions, you should not use our site.",
  textcon4: "1. Acceptance of Terms",
  textcon5: "By using our site, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions, as well as any other applicable laws and regulations. These terms and conditions constitute a binding agreement between you and our company.",
  textcon6: "2. Use of Site",
  textcon7: "You may use our site for lawful purposes and in accordance with these terms and conditions. You agree not to use our site for any illegal or unauthorized purpose, including but not limited to violating any intellectual property rights or privacy rights.",
  textcon8: "3. User Accounts",
  textcon9: "To access certain features of our site, you may be required to create a user account. You agree to provide accurate and complete information when creating your account and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
  textcon10: "4. Job Listings",
  textcon11: "Our site may contain job listings posted by third-party employers. We do not endorse or guarantee the accuracy of any job listing, and we are not responsible for any job opportunity or employment relationship that may arise from your use of our site. You acknowledge that you use our site and rely on job listings at your own risk.",
  textcon12: "5. Privacy Policy",
  textcon13: "Your use of our site is also governed by our Privacy Policy. By using our site, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.",
  textcon14: "6. Intellectual Property",
  textcon15: "All content on our site, including but not limited to text, graphics, logos, images, and software, is the property of our company or our licensors and is protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, distribute, modify, or create derivative works of any content on our site without our prior written consent.",
  textcon16: "7. Limitation of Liability",
  textcon17: "To the fullest extent permitted by applicable law, our company and  its officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our site, including but not limited to damages for loss of profits, data, or other intangible losses.",
  textcon18: "8. Governing Law",
  textcon19: " These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is located, without regard to its conflict of laws principles. Any legal action or proceeding arising out of or relating to these terms and conditions shall be brought exclusively in the courts of that jurisdiction.",
  textcon20: "9. Changes to Terms and Conditions",
  textcon21: "We reserve the right to modify or update these terms and conditions at any time without prior notice. Your continued use of our site after any such changes constitutes your acceptance of the new terms and conditions. We encourage you to review these terms and conditions periodically.",
  textcon22: "10. Contact Us",
  textcon23: "If you have any questions or concerns about these terms and conditions, please contact us using the contact information provided on our site.",
  textconclose: "Close",
  textCompany: "Company",
  textpop: "Popular searches",
  textv: `A clear <span class="text-bold2">vision</span>`,
  textm: `A possible <span class="text-bold2">mission</span>`,
  texts: "who are we",
  textvision: " Be a major player in the field of Resource Management",
  textvision2: "Humans in Sub-Saharan Africa and in Cameroon in particular.",
  textmission1:
    "Provide practical solutions to guide and support organizations, public and private companies, and individuals in managing human capital.",
  textmission2: "public and private companies, as well as individuals in",
  textmission3:
    "the framework for the development of their activities and skills.",
  textaboutf:
    `We are <span class="text-bold">Cible RH Emploi</span>, a leading company in the field of HR consulting and services. For more than twenty years, we have provided quality personnel at your disposal in Cameroon and Central Africa. Having only your success as our ambition, we combine an African vocation with international standards.`,
  textaboutT:
    "Cible RH Emploi offers a wide range of services to help you optimize the management of your resources over the long term",
  textaboutr:
    "Today, from our headquarters in Douala, 42 employees work tirelessly to meet your needs and help you achieve your ambitions.",
  findJob: "Search and find your dream job",
  dayJob: "Jobs of the day",
  logtext: "The #1 Job Board for Find the job that suits you",
  AllJobs: "All Jobs",
  jobExpired: "Closed",
  passMustMatch: "Passwords must match",
  textShortcuts: "Shortcuts",
  required: "Required",
  textRequiredField: "This field is required",
  noPassProvided: "No password provided.",
  passToShort: "Password is too short - should be 8 chars minimum.",
  passOnlyLatinLetters: "Password can only contain Latin letters.",
  startForFree: "Start for free Today",
  acessAllFeatures: "Access to all features. No credit card required.",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  password: "Password",
  confirmPassword: "Confirm Password",
  agreeTerms: "Agree our terms and policy",
  learnMore: "Learn more",
  submitNRegister: "Submit & Register",
  alreadyHaveAccount: "Already have an account?",
  signIn: "Sign in",
  rememberMe: "Remenber me",
  forgotPassword: "Forgot Password",
  actionLogin: "Login",
  dontHaveAccount: "Don't have an Account ?",
  signUp: "Sign up",
  phoneNumber: "Phone number",
  emailValidationError: "Email validation error;",
  myProfiles: "My CVs",
  menu_user: "User",
  addNew: "Add New",
  updateYourCV: "Update your CV",
  uploadFile: "Upload File",
  buttonDelete: "Delete",
  postTitle: "Post title",
  minSalary: "Minimum salary",
  maxSalary: "Maximum salary",
  userBio: "Bio",
  btnContinue: "Continue",
  profileSkills: "Profile skills",
  btnUpdate: "Update",
  titleEducation: "Education",
  educationFrom: "From",
  educationTo: "To",
  educationDegree: "Degree",
  educationSchool: "School",
  educationGpa: "Grade",
  educationfieldOfStudy: "Field of study",
  educationCollege: "College",
  currentlyWorking: "Currently working here",
  currentlyStudying: "Currently Sudying here",
  currentlyShort: "Current",
  addTimeline: "Add Timeline",
  educationAdded: "Education successfully added on your profile",
  menuHome: "Home",
  myResume: "My Resume",
  editTimeline: "Edit Timeline",
  educationEdited: "Education successfully edited",
  deletionAreYouSure: "Are you sure?",
  deletionIrreversibleAlert: "You won't be able to revert this!",
  deletionActionButton: "Yes, delete it!",
  deletionDeleted: "Deleted!",
  deletionSuccessMessage: "Your deletion operation succeed.",
  deletionActionCancel: "Cancel",
  deletionProcess: "Deletion in progress",
  requestGlobalErrorMessage: "Something went wrong",
  experienceCompany: "Company",
  textDescrption: "Description",
  workExperience: "Work & Experience",
  experienceAdded: "Experience successfully added on your profile",
  experienceEdited: "Experience successfully edited",
  experienceSector: "Activity sector",
  markCvDefault: "Mark cv as default",
  deleteButtonText: "Delete",
  introEditedSuccessfuly: "Profile successfuly edited",
  textDefault: "Default",
  jobsOfTheDay: "Jobs of the day",
  searchAndConnect: "Search and get the job if your dreams.",
  textSearch: "Search",
  postAJob: "Post a Job",
  textAdmin: "Admin",
  postNewJob: "Post New Job",
  jobInformations: "Job informations",
  jobTitle: "Job title",
  jobType: "Type",
  workplaceType: "Workplace type",
  workingTypePart: "Part time",
  workingTypeFull: "Full",
  workingTypeRemote: "Remote",
  workingTypeFreelancer: "Freelancer",
  jobExperienceYear: "Nombre d'années d'expérience",
  jobExperienceLevel: "Experience Level",
  jobExpEntry: "Entry",
  jobExpInterm: "Intermediate",
  jobExpSenior: "Senior",
  jobExpExpert: "Expert",
  jobMinSalary: "Min. salary",
  jobMaxSalary: "Max. salary",
  jobWorkPlaceOnsite: "On site",
  jobWorkPlaceRemote: "Remote",
  jobWorkPlaceHybrid: "Hybrid",
  jobApplicationEnd: "Application end",
  jobVisaSponsored: "Visa Sponsored",
  jobDescription: "Description",
  jobResponsabilities: "Job responsabilities",
  jobSpecialNote: "Special job note for user",
  jobIndustry: "Industry",
  jobCompany: "Company recruiting",
  jobSkills: "Skills",
  textJobs: "Jobs",
  jobCountry: "Country",
  jobCity: "City",
  textAboutUs: "About Us",
  jobProcess: "Recruitment processes (Separate with commas)",
  jobIncludePertners: "Include partners",
  textJobDetails: "Job Details",
  textJobApplications: "Job Applications",
  textCvTheque: "Cv Theque",
  textProfileDetails: "Profile details",
  textDownloadCv: "Download CV",
  textProfileSteps: "Etape du profil",
  textShortBio: "Short Bio",
  textSkills: "Skills",
  textAboutMe: "About Me",
  textOverview: "Overview",
  textExpectedSalary: "Expected Salary",
  textLinkedIn: "LinkedIn",
  textPhone: "Phone",
  textEmail: "Email",
  buttonSendMessage: "Send Message",
  textMyProfile: "My Profile",
  textUpdateProfile: "Update your profile",
  textUploadAvatar: "Upload Avatar",
  textFirstName: "First Name",
  textLastName: "Last Name",
  textPhoneNumber: "Phone Number",
  textLanguage: "Language",
  buttonSaveChange: "Save Change",
  textContactInformation: "Contact Information",
  textLngFrench: "French",
  textLngEnglish: "English",
  textContactInformation: "Contact Information",
  textSocialNetwork: "Social Network",
  textTwitter: "Twitter",
  textWebsite: "Website",
  textLinkedin: "Linkedin",
  textTwitter: "Twitter",
  textPerMonth: "Month",
  textEmploymentInfo: "Employment Information",
  textIndustry: "Industry",
  textJobLevel: "Job level",
  textSalary: "Salary",
  textExperience: "Experience",
  textYears: "Years",
  textJobType: "Job type",
  textCreated: "Created",
  textDeadline: "Deadline",
  textLocation: "Location",
  textApplyNow: "Apply now",
  textShareJob: "Share this",
  textSimilarJobs: "Similar jobs",
  footerDescription:
    "Cible Rh emploi is the leader in the sector of personnel placement and HR consulting in Cameroon and Central Africa",
  footerTitleContactInfo: "Contact Info",
  footerAddress: "Address",
  footerTitleLinks: "Links",
  textAllJobs: "All jobs",
  textContact: "Contact",
  footerTitleNewsletter: "Newsletter",
  footerTextNewslettet:
    "Subscribe to our newsletter to receive all our offers continuously.",
  textJObsAvailable: "Jobs Available",
  textFindTheJOb: "",
  textJobBoard: `
    The <span class="text-primary">#1 Job Board for</span>
                <br class="d-none d-lg-block" />
                Find the job that suits you`,
  textYourKeyword: "Your keyword... ",
  textInscriptionCreateAccount: "Sign up & create profiles",
  textInscriptionCreateAccountText:
    "Is this your first visit to our site? If so, we invite you to create an account and complete your profile to complete the application process.",
  textSearchJob: "Search job",
  textSearchJobText:
    "Enter a job title or keyword, Enter a location Click on the find a job button.",
  textHowToApply: "How to apply ?",
  textkeyword: "Keyword",
  textHowToApplyText:
    "Once you have found an offer you like, you have two choices: apply using your profile, or with your CV",
  textSeeSomeWords: "See Some Words",
  textThousand: "Thousands of employee get their ideal jobs",
  andFeedBack: "and feed back to us!",
  jobApplicationSuccess: "Application success",
  textNewProfile: "New profile",
  myApplications: "My applications",
  textAppliedOn: "Applied on",
  textLoginingOut: "Login out ...",
  textDashboard: "Dashboard",
  textApplications: "Applications",
  textMyProfile: "My Profile",
  cvManage: "CV Manage",
  textLogout: "Logout",
  textAdvanceFilter: "Advance Filter",
  textReset: "Reset",
  textDescription: "Description",
  tetSkills: "Skills",
  textResponsabilities: "Responsabilities",
  textProfileEditedSuccess: "Profile sucessfuly edited",
  textApplicationStatus: "Application status",
  text_sent: "Sent",
  text_in_progress: "In progress",
  text_interview: "Interview",
  text_test: "Test",
  text_hr: "RH",
  text_human_ressources: "Hman ressources",
  text_rejected: "Rejected",
  text_validated: "Validated",
  text_waiting_user: "Waiting your confirmation",
  text_accepted: "Accepted",
  text_canceled: "Canceled",
  textHome: "Home",
  textAvailableNow: "Available Now",
  textGithub: "Github",
  textMadeBy: "Made by",
  textUser: "User",
  textFindJob: "Find a Job",
  textYourAccount: "Your Account",
  textLogin: "Login",
  textRegister: "Register",
  textSignIn: "Login",
  textNoJob: "No job found",
  textLoginError: "Unable to log in with provided credentials.",
  textWelcomeBack: "Welcome back!",
  textAtMost: "The email should have at most 50 characters",
  textValidEmail: "Email address must be a valid address",
  textEmailNotFound: "Email not found",
  textResetPw: "Reset password",
  textRecoverPwEmailSend: "Password reset e-mail has been sent.",
  textConfirmError: "Password reset confirmation failed",
  textPwMustMatch: "Your passwords do no match",
  textSetNewPassword: "Set New Password",
  textNoNotifs: "No notifications found",
  textNotifications: "Notifications",
  textTitle: "Title",
  textContent: "Content",
  textNotificationsDetails: "Notification Details",
  textNotifDetailsError:
    "Sorry, we cannot display this notification, please try later",
  textProfileEditedError: "Sorry, we can't edit the profile, and error occured",
  textEmailVerified: "Email successfuly verified",
  textResendVerificationAction: "Resend",
  textResendVerification: "Resend Verification code",
  textCheckEmail: "Check email and try again",
  textKeyResend: "Verification code has been resend. Check your mail box!",
  emailRevalidationError:
    "We cannot send the revalidation code, please check your email address and try again.",
  textEmailVerificationKeyNotFound:
    "Email verification key not found. Try login or resend the verification link.",
  textNberPlaces: "Opened places",
  textNberAppl: "Applications",
  textGender: "Gender",
  textAge: "Age",
  textChoose: "Choose an option",
  textFemale: "Female",
  textMale: "Male",
  textNewsWill: `New Things Will Always<br/> Update Regularly`,
  textSubscribe: "Subscribe",
  textEnterEmail: "Enter your email here",
  textThankForSubscribing: "Thank you for subscribing to our newsletter.",
  textNewsletterFailed: "Your subscription failed.",
  textOurProcess: "Our process",
  textAppliedJobs: "Applications",
  textJobsCount: "Jobs count",
  textJobFound: "Job found",
  textProfiles: "Profiles",
  textRecommendedJobs: "Recommended jobs",
  textTopRecruitment: "Top recruitment",
  textApplicationDetails: "Application details",
  textHistory: "History",
  textApplicationInformations: "Application informations",
  // textAppliedOn: "Applied on",
  textIsActive: "Is active",
  textYes: "Yes",
  textNo: "No",
  textStep: "Step",
  textJob: "Job",
  textPreviousStep: "Previous step",
  textApplicationNotFound: "Applicationn not found",
  textActions: "Actions",
  textCancelApplication: "Cancel my application",
  textAcceptJob: "Accept job",
  textYesCancel: "Yes, cancel",
  textApplicationCancelError:
    "An error occured while cancelling your application",
  textApplicationCancelled: "Job application canncelled !",
  textApplicationAccepted: "Application accepted",
  textApplicationAcceptationError: "Application acceptation failed",
  textIntroduction: "Introduction",
  textCertifications: "Certifications",
  textLanguages: "Languages",
  textReferences: "References",
  textAddEducation: "Add education",
  textEditEducation: "Edit education",
  textPrevious: "Previous",
  textNext: "Next",
  textAddExperience: "Add experience",
  textEditExperience: "Edit experoence",
  textCertifName: "Certification name",
  textDate: "Date",
  textCertifAdded: "Certification successfully added",
  textCertifAddingError: "Certification creation error!",
  textAddCertification: "Add certification",
  textEditCertification: "Edit certification",
  textJoinedFile: "Joined files",
  textLangName: "Language name",
  textLevel: "Level",
  textAddLanguage: "Add language",
  textEditLanguage: "Edit language",
  textLanguageAddingError: "Language adding error !",
  languageEdited: "Language successfully edited",
  textLanguageAdded: "Language successfully added",
  textName: "Name",
  textAddReference: "Add reference",
  textEditReference: "Ediit reference",
  textReferenceAddingError: "Reference adding error !",
  referenceEdited: "Reference successfully edited",
  textReferenceAdded: "Reference successfully added",
  textViewResumes: "View My resumes",
  textApplication: "Job Application",
  textStartUrCareer: "Start your career today",
  textChooseProfile: "Please choose your profile and send it to the employer.",
  textFalse: "False",
  textTrue: "True",
  textAdditionalInformations: "Additional informations",
  textFormFieldRequired: "This field is required",
  textDateObtained: "Date Obtained",
  textSubmit: "Submit",
  textBeginner: "Beginner",
  textIntermediate: "Intermediate",
  textAdvanced: "Advanced",
  textPopCat: "Popular category",
  textPopCatText: "Connect with the right candidates faster.",
  textByLocation: "Jobs by Location",
  textByLocationText: "Find your favorite jobs and benefit from our benefits",
  textRole: "Role",
  textBestRecruiting: "The sectors that recruit the most.",

  //
  textYouMustAgreeTerms: "You must agree terms before creating your account.",
  textCvMarkedAsDefault: "Profile marked as default.",
  textCvMarkedAsDefaultError:
    "An error occured while marking the profile as default.",
  textVacancy: "Vacancy",
  textVacancys: "Vacancies",
  profession: "Profession",
  yearOfExperience: "Years of Experience",
  textAll: "All",
  textMoreDetails: "More about us",

  textDesigner:"Designer",
  textDeveloper:"Developer",
  textEngineer:"Engineer",
  textPOBox: "P.O.BOX.",
  textLinkSent: "A message was sent to your address. Click on the link to validate your account",
  textSignupSuccess: "Registration successfull",
  textSignupSuccessMsg: "A link has been sent to your email account. Click on it to validate Your account and start the Journey"
};
