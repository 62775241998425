import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react"; // Correction ici
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./home.css";

const PopCategory = ({ categories }) => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);

  useEffect(() => {
    // S'assurer que Swiper est initialisé après le montage du composant
    const swiper = new Swiper(swiperRef.current, {
      modules: [Navigation, Pagination],
      spaceBetween: 30,
      slidesPerView: 6,
      slidesPerGroup: 2,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 10000,
      },
      breakpoints: {
        1360: {
          slidesPerView: 6,
        },
        1199: {
          slidesPerView: 5,
        },
        992: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 3,
        },
        400: {
          slidesPerView: 2,
        },
        250: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 15,
        },
      },
    });

    return () => swiper.destroy(); // Nettoyage sur le démontage
  }, [categories]); // Dépendance à 'categories' pour la réinitialisation

  return (
    <section className="section-box mt-50">
      <div className="container">
        <div className="text-start">
            <h2 className="section-title text mb-10 wow animate__animated animate__fadeInUp">
              {t("textPopCat")}
            </h2>
            <p className="font-lg text-danger color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              {t("textPopCatText")}
            </p>
          </div>
        <div className="box-swiper mt-50">
          <div className="swiper-container swiper-group-6 mh-none swiper" ref={swiperRef}>
            <div className="swiper-wrapper pb-70 pt-5">
              {/* ... */}
              {_.orderBy(
                categories,
                ["jobs.length"], // Simplification de la fonction orderBy
                ["desc"]
              )?.map((category) => (
                <div key={"pop_category" + category.id} className="swiper-slide hover-up">
                <div
                      className="card-grid-5 card-category hover-up"
                      style={{ backgroundImage: `url(${category.logo})` }}
                    >
                      <Link to={`/search/?search=&industry=${category.id}`}>
                        <div className="box-cover-img">
                          <div className="content-bottom">
                            <h6 className="color-white mb-5">
                              {category?.name}
                            </h6>
                            <p className="color-white font-xs">
                              <span>{category.jobs.length}</span>
                              <span> {t("textJObsAvailable")}</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                </div>
              ))}
            </div>
          </div>
          <div className="swiper-button-next swiper-button-next-1"></div>
          <div className="swiper-button-prev swiper-button-prev-1"></div>
        </div>
      </div>
    </section>
  );
};

export default PopCategory;
