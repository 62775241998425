import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../../services/axios";
import { URLS } from "../../../../services/urls";
import {
  getUserDetails,
  setUserDetails,
} from "../../../../components/utils/functions";
import { toast } from "react-toastify";
import { updateUserData } from "../../../../store/slices/auth";
import i18n from "../../../../translations/translations";
import Footer from "../../../../components/footer";
import AuthFooter from "../../components/footer";

const MyProfile = (props) => {
  const [picture, setPicture] = useState();
  const [loading, setLoading] = useState();
  const [loadingSocial, setLoadingSocial] = useState();
  const [loadingUser, setLoadingUser] = useState(true);
  const [preview, setPreview] = useState("");
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const {
    register: registerSocial,
    handleSubmit: handleSubmitSocial,
    reset: resetSocial,
  } = useForm();

  const handleChangeProfile = (event) => {
    setPicture(event?.target?.files[0]);
    setPreview(URL.createObjectURL(event?.target?.files[0]));
  };

  const onSubmitSocials = (data) => {
    setLoadingSocial(true);
    const info = {
      linkedin: data.linkedin,
      github: data.github,
      website: data.website,
      twitter: data.twitter,
    };
    API.patch(URLS.USERS.update(auth?.user?.id), info)
      .then((resp) => {
        const user = getUserDetails();
        const { data } = resp;
        setUserDetails({
          ...user,
          linkedin: data.linkedin,
          github: data.github,
          // email: data.email,
          website: data.website,
          twitter: data.twitter ?? "",
        });
        setLoadingSocial(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        setLoadingSocial(false);
      });
  };

  const onSubmit = (data) => {
    setLoading(true);
    let data1 = data;

    let formData = new FormData();
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append("phone_number", data?.phoneNumber);
    formData.append("language", data?.language);
    formData.append("age", data?.age);
    formData.append("gender", data?.gender);

    if (picture) {
      formData.append("picture", picture);
    }

    console.log(auth?.user);

    API.patch(URLS.USERS.update(auth?.user?.id), formData)
      .then((resp) => {
        const user = getUserDetails();
        const { data } = resp;
        setUserDetails({
          ...user,
          first_name: data.first_name,
          last_name: data.last_name,
          // email: data.email,
          phone_number: data.phone_number,
          language: data.language ?? "eng",
          age: data.age ?? "",
          gender: data.gender ?? "",
          picture: data.picture ?? user?.picture,
        });
        setLoading(false);
        toast.success(t("textProfileEditedSuccess"));
        dispatch(updateUserData({ ...data, groups: user.groups }));
        // change language in local
        i18n.changeLanguage(data?.language === "fr" ? data.language : "eng");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        setLoading(false);
      });
  };

  useEffect(() => {
    API.get(URLS.AUTHS.me)
      .then((resp) => {
        const { data } = resp;
        reset({
          firstName: data?.first_name ?? "",
          lastName: data?.last_name ?? "",
          email: data?.email ?? "",
          phoneNumber: data?.phone_number ?? "",
          language: data?.language ?? "eng",
          age: data?.age ?? "",
          gender: data?.gender ?? "",
        });

        // Reset social form
        resetSocial({
          linkedin: data.linkedin ?? "",
          github: data.github ?? "",
          website: data.website ?? "",
          twitter: data.twitter ?? "",
        });
        if (data?.picture) {
          if (data?.picture?.includes("https://")) {
            setPreview(data?.picture);
          } else {
            setPreview(URLS.BASE_URL + data?.picture);
          }
        } else {
          setPreview("/assets/imgs/page/profile/img-profile.png");
        }
        setLoadingUser(false);
      })
      .catch((error) => {
        if (auth) {
          const { user } = auth;
          reset({
            firstName: user?.first_name ?? "",
            lastName: user?.last_name ?? "",
            email: user?.email ?? "",
            phoneNumber: user?.phone_number ?? "",
            language: user?.language ?? "eng",
            age: user?.age ?? "",
            gender: user?.gender ?? "",
          });
          if (user?.picture) {
            if (user?.picture?.includes("https://")) {
              setPreview(user?.picture);
            } else {
              setPreview(URLS.BASE_URL + user?.picture);
            }
          } else {
            setPreview("/assets/imgs/page/profile/img-profile.png");
          }
        }
        setLoadingUser(false);
      });
  }, [auth]);

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("textMyProfile")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textHome")}
                </Link>
              </li>
              <li>
                <span>{t("textMyProfile")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {loadingUser && (
        <div className="d-flex">
          <div
            className="spinner-border m-auto spinner-border-md"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loadingUser && (
        <div className="row">
          <div className="col-xxl-9 col-xl-8 col-lg-8">
            <div className="section-box">
              <div className="container">
                <div className="panel-white mb-30">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="box-padding">
                      <h6 className="color-text-paragraph-2">
                        {t("textUpdateProfile")}
                      </h6>
                      <div className="box-profile-image">
                        <div className="img-profile">
                          {" "}
                          <img src={preview} alt={auth?.user?.first_name} />
                        </div>
                        <div className="info-profile">
                          {" "}
                          <input
                            type="file"
                            hidden
                            id="inputAvatar"
                            onChange={handleChangeProfile}
                          />{" "}
                          <label
                            className="btn btn-default"
                            htmlFor="inputAvatar"
                          >
                            {t("textUploadAvatar")}
                          </label>
                          {/* <Link className="btn btn-link">{t('textDelete')}</Link> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textFirstName")} *
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              {...register("firstName")}
                              placeholder="Steven"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textLastName")} *
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              {...register("lastName")}
                              placeholder="Job"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textEmail")} *
                            </label>
                            <input
                              disabled
                              className="form-control"
                              type="text"
                              {...register("email")}
                              placeholder="stevenjob@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textPhoneNumber")}
                            </label>
                            <input
                              className="form-control"
                              type="tel"
                              {...register("phoneNumber")}
                              placeholder="01 - 234 567 89"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group mb-30">
                          <label className="font-sm color-text-mutted mb-10">Personal website</label>
                          <input className="form-control" type="text" placeholder="https://alithemes.com"/>
                        </div>
                      </div> */}
                        {/* <div className="col-lg-12"> 
                        <div className="form-group mb-30"> 
                          <label className="font-sm color-text-mutted mb-10">Bio</label>
                          <textarea className="form-control" name="message" rows="5">We are AliThemes , a creative and dedicated group of individuals who love web development almost as much as we love our customers. We are passionate team with the mission for achieving the perfection in web design.</textarea>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group mb-30"> 
                          <label className="font-sm color-text-mutted mb-10">Experience</label>
                          <input className="form-control" type="text" placeholder="1 - 5 Years"/>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group mb-30">
                          <label className="font-sm color-text-mutted mb-10">Education Levels</label>
                          <input className="form-control" type="text" placeholder="Certificate"/>
                        </div>
                      </div> */}
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textLanguage")}
                            </label>
                            <select
                              className="form-control"
                              {...register("language")}
                            >
                              <option value="fr">{t("textLngFrench")}</option>
                              <option value="eng">{t("textLngEnglish")}</option>
                            </select>{" "}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label
                              className="font-sm color-text-mutted mb-10"
                              htmlFor="form-sex"
                            >
                              {t("textGender")}
                            </label>
                            <select
                              id="form-sex"
                              className="form-control"
                              {...register("gender")}
                            >
                              <option value="">{t("textChoose")}</option>
                              <option value="male">{t("textMale")}</option>
                              <option value="female">{t("textFemale")}</option>
                            </select>{" "}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textAge")}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              min={15}
                              max={120}
                              {...register("age")}
                              placeholder="18"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group mb-30">
                          <label className="font-sm color-text-mutted mb-10">Categories </label>
                          <input className="form-control" type="text" placeholder="UI/UX designer"/>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group mb-30">
                          <label className="font-sm color-text-mutted mb-10">Current Salary($)</label>
                          <input className="form-control" type="text" placeholder="$2500"/>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group mb-30">
                          <label className="font-sm color-text-mutted mb-10">Expected Salary($) </label>
                          <input className="form-control" type="text" placeholder="$3500"/>
                        </div>
                      </div> */}
                        <div className="col-lg-12">
                          <div className="form-group mt-10">
                            <button
                              type="submit"
                              className="btn btn-default btn-brand"
                            >
                              {t("buttonSaveChange")}
                              {loading && (
                                <div
                                  className="spinner-border ml-10 spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="panel-white mb-30">
                <div className="box-padding">
                  <h6 className="color-text-paragraph-2">
                    {t("textContactInformation")}
                  </h6>
                  <div className="row mt-30">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-30">
                        <label className="font-sm color-text-mutted mb-10">
                          Country
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="United States of America"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-30">
                        <label className="font-sm color-text-mutted mb-10">
                          City
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Chicago"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-30">
                        <label className="font-sm color-text-mutted mb-10">
                          Complete Address
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="205 North Michigan Avenue, Suite 810, Chicago, 60601, USA"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-30">
                        <label className="font-sm color-text-mutted mb-10">
                          Find On Map
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="205 North Michigan Avenue, Suite 810, Chicago, 60601, USA"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-30">
                        <label className="font-sm color-text-mutted mb-10">
                          Latitude
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="41.881832"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-30">
                        <label className="font-sm color-text-mutted mb-10">
                          Longitude
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=" -87.623177"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-30">
                        <label className="font-sm color-text-mutted mb-10">
                          Google Map
                        </label>
                        <div className="box-map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.4860084541583!2d-87.62575418429162!3d41.88608087922149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2ca8b34afe61%3A0x6caeb5f721ca846!2s205%20N%20Michigan%20Ave%20Suit%20810%2C%20Chicago%2C%20IL%2060601%2C%20Hoa%20K%E1%BB%B3!5e1!3m2!1svi!2s!4v1663165156864!5m2!1svi!2s"
                            width="100%"
                            height="400"
                            style={{ border: 0 }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mt-0">
                        <button className="btn btn-default btn-brand icon-tick">
                          Save Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4">
            <div className="section-box">
              <div className="container">
                <div className="panel-white">
                  <div className="panel-head">
                    <h5>{t("textSocialNetwork")}</h5>
                  </div>
                  <div className="panel-body pt-20">
                    <form onSubmit={handleSubmitSocial(onSubmitSocials)}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="col-lg-12">
                            <div className="form-group mb-30">
                              <label className="font-sm color-text-mutted mb-10">
                                {t("textWebsite")}
                              </label>
                              <input
                                className="form-control"
                                type="url"
                                placeholder="https://www.example.com"
                                {...registerSocial("website")}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textLinkedin")}
                            </label>
                            <input
                              className="form-control"
                              type="url"
                              placeholder="https://www.linkedin.com"
                              {...registerSocial("linkedin")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textTwitter")}
                            </label>
                            <input
                              className="form-control"
                              type="url"
                              placeholder="https://twitter.com"
                              {...registerSocial("twitter")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-30">
                            <label className="font-sm color-text-mutted mb-10">
                              {t("textGithub")}
                            </label>
                            <input
                              className="form-control"
                              type="url"
                              placeholder="https://www.github.com"
                              {...registerSocial("github")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mt-0">
                            <button className="btn btn-default btn-brand icon-tick">
                              {t("buttonSaveChange")}
                              {loadingSocial && (
                                <div
                                  className="spinner-border ml-10 spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="section-box">
            <div className="container">
              <div className="panel-white">
                <div className="panel-head">
                  <h5>My Skill</h5>
                  <Link
                    className="menudrop"
                    id="dropdownMenu3"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  ></Link>
                  <ul
                    className="dropdown-menu dropdown-menu-light dropdown-menu-end"
                    aria-labelledby="dropdownMenu3"
                  >
                    <li>
                      <Link className="dropdown-item active" href="#">
                        Add new
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">
                        Settings
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">
                        Actions
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="panel-body pt-20">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-30">
                        <input
                          className="form-control icon-search-right"
                          type="text"
                          placeholder="E.g. Angular, Laravel..."
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-20">
                        <Link className="btn btn-tag tags-link">
                          Figma<span></span>
                        </Link>
                        <Link className="btn btn-tag tags-link">
                          Adobe XD<span></span>
                        </Link>
                        <Link className="btn btn-tag tags-link">
                          NextJS<span></span>
                        </Link>
                        <Link className="btn btn-tag tags-link">
                          React<span></span>
                        </Link>
                        <Link className="btn btn-tag tags-link">
                          App<span></span>
                        </Link>
                        <Link className="btn btn-tag tags-link">
                          Digital<span></span>
                        </Link>
                        <Link className="btn btn-tag tags-link">
                          NodeJS<span></span>
                        </Link>
                      </div>
                      <div className="mt-30 mb-40">
                        {" "}
                        <span className="info-icon font-sm color-text-paragraph-2">
                          You can add up to 15 skills
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mt-0">
                        <button className="btn btn-default btn-brand icon-tick">
                          Save Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div> */}
          </div>
        </div>
      )}

      <AuthFooter />
    </div>
  );
};

export default MyProfile;
