import { useTranslation } from "react-i18next";
import { URLS } from "../../../../services/urls";
import { useEffect, useRef, useState } from "react";
import { API } from "../../../../services/axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmDeletion } from "../../../../components/dletion";
import { ErrorMessage } from "@hookform/error-message";

const ManageReferences = (props) => {
  const {resume, handleNext, handlePrev, handleRefetch} = props;
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [references, setReferences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toEdit, setToEdit] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState();
  const { action } = useParams();

  const editorRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm({});

  const formRef = useRef(null)

  const handleChangeReference = (reference) => {
    setToEdit(reference);
    reset({
      email: reference?.email,
      name: reference?.name,
      phone: reference?.phone_number,
      role: reference?.role,
    });
    formRef?.current?.scrollIntoView()
  }

  const handleDeleteReference = (certification) => {

    const onSuccess = () => {
        setReferences((prev) => {
        return prev.filter(item => item.id != certification.id)
      })
    }
    confirmDeletion(URLS.USERS.PROFILE.REFERENCE.update(certification.id), t, onSuccess)
  }

  const onSubmit = (data) => {
    console.log(selected);
    setLoading(true);
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("phone_number", data.phone);
    formData.append("name", data.name);
    formData.append("role", data.role);
    formData.append("profile", action);

    if (!toEdit) {
      API.post(URLS.USERS.PROFILE.REFERENCE.create, formData)
        .then((resp) => {
          setLoading(false);
          reset();
          toast.success(t("textReferenceAdded"));
          setReferences((prev) => [{...resp.data }, ...prev]);
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail ?? error?.response?.data?.messagerror ?? t("textReferenceAddingError"));
          setLoading(false);
        });
    } else {
      API.patch(URLS.USERS.PROFILE.REFERENCE.update(toEdit.id), formData)
        .then((resp) => {
          setLoading(false);
          const { data } = resp;

          toast.success(t("referenceEdited"));
          setReferences((prev) => {
            const eds = prev?.map(ed => {
              if(ed.id === toEdit.id) {
                ed.name = data.name;
                ed.phone_number = data.phone_number;
                ed.email = data.email;
                ed.role = data.role;
              }
              return ed
            })
            return eds
          });
          setToEdit();
          reset();
          setSelected();
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (resume){
        setReferences(resume.reference)
    }
  }, [resume])

  return (
    <div className="panel-white mb-30" id="education">
      <div className="box-padding">
        <h5 className="icon-edu">{t("textReferences")}</h5>
        <div className="row mt-30">
          <div className="col-lg-9">
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <div className="row">
              <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textName")}
                    </label>
                    <input
                      {...register("name", { required: t("textFormFieldRequired") })}
                      className="form-control"
                      type="text"
                      placeholder={t("textName")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textEmail")}
                    </label>
                    <input
                      {...register("email", { required: t("textFormFieldRequired") })}
                      className="form-control"
                      type="email"
                      placeholder={t("textEmail")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textPhone")}
                    </label>
                    <input
                      {...register("phone", { required: t("textFormFieldRequired") })}
                      className="form-control"
                      type="tel"
                      placeholder={t("textPhone")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="phone"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textRole")}
                    </label>
                    <input
                      {...register("role", { required: t("textFormFieldRequired") })}
                      className="form-control"
                      type="text"
                      placeholder={t("textRole")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-10 d-flex">
                    <button className="btn btn-default btn-brand icon-tick ml-10">
                      {!toEdit ? t("textAddReference") : t("textEditReference")}
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm ml-10"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="box-timeline mt-50">
              {
                references.map((language) => (<div key={language.id} className="item-timeline">
                <div className="timeline-year">
                  
                </div>
                <div className="timeline-info">
                  <h5 className="color-brand-1 mb-20">{language?.name}</h5>
                  <p className="color-text-paragraph-2 mb-15">
                    {t("textPhone")}: <Link to={`tel:${language.phone_number}`}>{language.phone_number}</Link><br />
                    {t("textEmail")}: <Link to={`mailto:${language.email}`}>{language.email}</Link> <br />
                  </p>
                </div>
                <div className="timeline-actions">
                  {" "}
                  <Link to={"#"} onClick={(e) => handleChangeReference(language)} className="btn btn-editor position-inherit m-auto"></Link>
                  <Link to="#" onClick={(e) => handleDeleteReference(language)} className="btn btn-remove m-auto"></Link>
                </div>
              </div>))
              }
            </div>
            <div className="col-lg-12 mt-3">
                  <div className="form-group mt-10 d-flex">
                  <button onClick={handlePrev} className="btn btn-secondary btn-brand line-height-24">
                        {t("textPrevious")}
                    </button>
                    <Link to="/dashboard/my-resumes"  className="btn btn-default btn-brand ml-auto">
                    {t("textViewResumes")}
                    </Link>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReferences;
