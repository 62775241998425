import { Link, useLocation } from "react-router-dom";
import { logout } from "../../services/logout";
import { useTranslation } from "react-i18next";


const NavBar = (props) => {

    const { group } = props;
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <div className="nav"><Link className="btn btn-expanded"></Link>
        <nav className="nav-main-menu">
          <ul className="main-menu">
            <li> <Link className={`dashboard2 ${pathname === "/dashboard" ? 'active' : ''}`} to="/dashboard"><img src="/assets/imgs/page/dashboard/dashboard.svg" alt="Cible rh emploie" /><span className="name">{t("textDashboard")}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/dashboard/my-applications") ? 'active' : ''}`} to="/dashboard/my-applications"><img src="/assets/imgs/page/dashboard/tasks.svg" alt="Cible rh emploie" /><span className="name">{t("textApplications")}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/dashboard/profile") ? 'active' : ''}`} to="/dashboard/profile"><img src="/assets/imgs/page/dashboard/profiles.svg" alt="Cible rh emploie" /><span className="name">{t('textMyProfile')}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/dashboard/my-resumes") ? 'active' : ''}`} to="/dashboard/my-resumes"><img src="/assets/imgs/page/dashboard/cv-manage.svg" alt="Cible rh emploie" /><span className="name">{t("cvManage")}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/logout") ? 'active' : ''}`} to="#" onClick={() => logout(t)}><img src="/assets/imgs/page/dashboard/logout.svg" alt="Cible rh emploie" /><span className="name">{t("textLogout")}</span></Link>
            </li>
          </ul>
        </nav>
        <div className="border-bottom mb-20 mt-20"></div>
        {/* <div className="box-profile-completed text-center mb-30">
          <div id="circle-staticstic-demo"></div>
          <h6 className="mb-10">Profile Completed</h6>
          <p className="font-xs color-text-mutted">Please add detailed information to your profile. This will help you develop your career more quickly.</p>
        </div>
        <div className="sidebar-border-bg mt-50"><span className="text-grey">WE ARE</span><span className="text-hiring">HIRING</span>
          <p className="font-xxs color-text-paragraph mt-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto</p>
          <div className="mt-15"><Link className="btn btn-paragraph-2" to="#">Know More</Link></div>
        </div> */}
      </div>
    )
}

export default NavBar;