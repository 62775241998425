import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatLinkString } from "../../../../../components/utils/functions";

const ResumeCard = (props) => {
  const { profile } = props;

  const { auth } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="col-xl-3 col-lg-4 col-md-6">
      <Link to={`/dashboard/my-resumes/manage/${profile?.id}/`}>
        <div className="card-style-2 hover-up">
          <div className="card-head">
            <div className="card-image">
              {" "}
              <img
                src={
                  auth?.user?.picture
                    ? formatLinkString(auth?.user?.picture)
                    : "/assets/imgs/page/dashboard/img1.png"
                }
                alt={profile?.primary_role}
              />
            </div>
            <div className="card-title">
              <h6>{profile?.primary_role}</h6>
              <span className="text-muted">
                {truncateText(profile?.bio, 10)}
              </span>{" "}
              {/* <span>1 days ago</span> */}
            </div>
          </div>

          <div className="mr-15">
            {" "}
            {profile?.default && (
              <a className="btn btn-tag planing">{t("textDefault")}</a>
            )}
            {/* <a className="btn btn-tag high">High</a> */}
          </div>
          {/* <div className="card-progress">
          <span>Complete: </span>
          <strong>80</strong>
          <span className="hour"> %</span>
        </div> */}
        </div>
      </Link>
    </div>
  );
};

export default ResumeCard;
