import { Link, useNavigate, useParams } from "react-router-dom";

const ProfileCard = (props) => {
  // jobs/:jobId/applications/:cvId/details
  const { profile, showUtils, active, newButtonLink, handleClose } = props;
  const { jobId } = useParams();
  // If jobId in not null the view is a list of candidates for specific job else its a standard cv-theque view

  const navigate = useNavigate();

  const handleNavigate = (event) => {
    event.preventDefault();
    if (handleClose) {
      handleClose();
    }
    navigate(newButtonLink ?? "#");
  };
  return (
    <div className={`card-grid-2 hover-up ${active ? "active" : ""}`}>
      <div className="">
        <div className="card-profile p-20 text-center">
          <Link to={"#"} onClick={handleNavigate}>
            <h6>{profile?.primary_role ?? "Role"}</h6>
          </Link>
          {/* <div className="rate-reviews-small pt-5">
            <span>
              <img src="/assets/imgs/template/icons/star.svg" alt="Cible rh emploie" />
            </span>
            <span>
              <img src="/assets/imgs/template/icons/star.svg" alt="Cible rh emploie" />
            </span>
            <span>
              <img src="/assets/imgs/template/icons/star.svg" alt="Cible rh emploie" />
            </span>
            <span>
              <img src="/assets/imgs/template/icons/star.svg" alt="Cible rh emploie" />
            </span>
            <span>
              <img src="/assets/imgs/template/icons/star.svg" alt="Cible rh emploie" />
            </span>
            <span className="ml-10 color-text-mutted font-xs">(65)</span>
          </div> */}
        </div>
      </div>
      {!showUtils && (
        <div className="card-block-info">
          <p className="font-xs color-text-paragraph-2">| {profile?.bio}</p>
          <div className="card-2-bottom card-2-bottom-candidate mt-30">
            <div className="text-start">
              {profile?.skills?.map((profile) => (
                <Link
                  key={profile?.id}
                  className="btn btn-tags-sm mb-10 mr-5"
                  to="#"
                >
                  {profile?.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="employers-info align-items-center justify-content-center mt-15">
            <div className="row">
              <div className="col-12">
                <span className="d-flex align-items-center">
                  <i className="fi-rr-marker mr-5 ml-0"></i>
                  <span className="font-sm color-text-mutted">
                    {profile?.user?.country}
                  </span>
                </span>
              </div>
              <div className="col-12">
                <span className="d-flex justify-content-start mt-10 align-items-center">
                  <i className="fi-rr-clock mr-5"></i>
                  <span className="font-sm color-brand-1">
                    XAF {profile?.salary_min} - {profile?.salary_max}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
