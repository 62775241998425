// core version + navigation, pagination modules:
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import _ from "lodash";

const HomeBanner = (props) => {
    const {categories,} = props;
    const { t } = useTranslation()
  const swiper = new Swiper(".swiper-group-5", {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay],
    spaceBetween: 15,
    slidesPerGroup: 3,
    slidesPerView: 5,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 10000,
    },
    breakpoints: {
      1199: {
        slidesPerView: 5,
      },
      800: {
        slidesPerView: 3,
      },
      475: {
        slidesPerView: 2,
      },
      350: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      275: {
        slidesPerView: 1,
      },
    },
  });

  return (
    <div className="container">
      <div className="box-swiper mt-50">
        <div className="swiper-container swiper-group-5 swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          <div
            className="swiper-wrapper pb-25 pt-5"
            id="swiper-wrapper-b8e5af532fc1c5f5"
            aria-live="off"
            // style={{transitionDuration: "0ms", transform: "translate3d(-3393px, 0px, 0px)"}}
          >
            {
                _.orderBy(categories, ['jobs', function (o) {
                    return o.length;
                 }], ["desc"])?.map((category) => (

                <div
                key={"banner_" + category.id}
                className="swiper-slide hover-up swiper-slide-duplicate"
                data-swiper-slide-index="5"
                role="group"
                aria-label="6 / 10"
                style={{width: "211.2px", marginRight: "15px"}}
                >
                <Link to={`/search/?search=&industry=${category.id}`}>
                    <div className="item-logo">
                    <div className="image-left">
                        {/* <img
                        alt="Cible rh emploie"
                        src={category.icon}
                        /> */}
                    </div>
                    <div className="text-info-right" style={{height: "68px"}}>
                        <h4>{category.name}</h4>
                        <p className="font-xs">
                        {category.jobs.length}<span> {t("textJObsAvailable")}</span>
                        </p>
                    </div>
                    </div>
                </Link>
                </div>
                ))
            }
          </div>
          <div className="swiper-pagination swiper-pagination-style-border swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            
          </div>
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          ></span>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
