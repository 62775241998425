import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ApplyButton from "../../../components/actions/ApplyButton";
import { formatLinkString } from "../../../components/utils/functions";

const JobCardList = (props) => {
  const { job } = props;
  const { t } = useTranslation();
  return (
    <Link to={`/job/${job?.slug}/details/`} className="name-job">
      <div className="col-xl-12 col-12">
        <div className="card-grid-2 hover-up">
          <span className="flash"></span>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card-grid-2-image-left">
                <div
                  className="image-box image-box-2"
                  style={{
                    backgroundImage: `url(${
                      job?.image
                        ? formatLinkString(job?.image)
                        : "/assets/imgs/page/homepage2/logo1.jpg"
                    })`,
                  }}
                ></div>
                <div className="right-info">
                  {job?.title}
                  <span className="location-small">
                    {job?.country?.length > 0 ? job.country[0]?.name : "N/A"}, 
                    {job?.city?.length > 0 ? job.city[0]?.name : "N/A"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-start text-md-end pr-60 col-md-6 col-sm-12">
              <div className="pl-15 mb-15 mt-30">
                {Array.isArray(job?.skills) && job.skills.slice(0, 2)?.map((skill) => (
                  <Link
                    key={skill?.id}
                    className="btn btn-tags-sm mr-5 text-paragraph-1 mb-5"
                    to="#"
                  >
                    {skill?.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="card-block-info">
            <h4></h4>
            <div className="mt-5">
              <span className="card-briefcase">{job?.type}</span>
              <span className="card-time">
                {moment(job?.created_at).fromNow()}
              </span>
              <span className="card-time bg-none p-0">
                {t("textNberPlaces")}: {job?.required}, {t("textNberAppl")}: 
                {job?.submitted}, {t("textDeadline")}: 
                {moment(job?.application_end).fromNow()}
              </span>
            </div>
            <p
              className="font-sm color-text-paragraph text-paragraph-2 mt-10"
              dangerouslySetInnerHTML={{ __html: job?.description || '' }}
            ></p>
            <div className="card-2-bottom mt-20">
              <div className="row">
                <div className="col-12 text-end">
                  {job?.closed ? (
                    <span className="job-expired-label">{t("jobExpired")}</span>
                  ) : (
                    <ApplyButton
                      key={job?.id + "elf"}
                      hideModal={true}
                      hideButton={false}
                      jobId={job?.id}
                      job={job}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default JobCardList;
