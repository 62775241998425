import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import { SignupConfirmation } from "./login-success";

const schema = (t) =>
  Yup.object({
    firstName: Yup.string().required(t("textRequiredField")),
    lastName: Yup.string().required(t("textRequiredField")),
    phoneNumber: Yup.string().required(t("textRequiredField")),
    email: Yup.string().email("").required(t("textRequiredField")),
    password: Yup.string()
      .required(t("noPassProvided"))
      .min(8, t("passToShort"))
      .matches(/[A-Z]/, t("passUpperCase"))
      .matches(/[a-z]/, t("passLowerCase"))
      .matches(/[0-9]/, t("passNumber"))
      .matches(/[@$!%*?&]/, t("passSpecialChar")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passMustMatch"))
      .required(t("textRequiredField")),
  }).required();

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh',
    width: '60%',
    overflowY: 'auto', // Permet le défilement à l'intérieur du modal
    padding: '20px',
    borderRadius: '10px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const Register = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema(t)),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = (data) => {
    if (!data.agree) {
      // Affichez une notification d'erreur si la case "agreeTerms" n'est pas cochée
      toast.error(
        t("Vous devez accepter les conditions générales pour vous inscrire.")
      );
      return;
    }

    if (data.agree) {
      setLoading(true);
      const infos = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone_number: data.phoneNumber,
        password: data.password,
        password2: data.confirmPassword,
        is_superuser: false,
      };
      API.post(URLS.AUTHS.REGISTRATION, infos)
        .then((resp) => {
          // console.log("Success response:", resp.data.detail);
          // toast.success(resp.data.detail);
          // toast.success(t("textLinkSent"));
          SignupConfirmation(t);
          setLoading(false);
          // console.log(resp.data);
          // setLoading(false);
          // console.log(resp.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          toast.error(error?.response?.data?.detail);
        });
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="box-content">
      <div className="banner-hero hero-2">
        <div className="banner-inner"></div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-3">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-3">
                      <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                            <img
                              src="assets/imgs/page/homepage2/logo.png"
                              style={{ width: "185px" }}
                              alt="logo"
                            />
                          </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="firstName">
                                {t("firstName")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="firstName"
                                type="text"
                                required=""
                                name="firstName"
                                placeholder="Steven"
                                {...register("firstName", { required: true })}
                              />
                              <span className="text-danger">
                                {errors.firstName?.message}
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="lastName">
                                {t("lastName")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="lastName"
                                type="text"
                                required=""
                                name="lastName"
                                placeholder="Job"
                                {...register("lastName", { required: true })}
                              />
                              <span className="text-danger">
                                {errors.lastName?.message}
                              </span>
                            </div>
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                {t("phoneNumber")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="phoneNumber"
                                type="text"
                                required=""
                                name="phoneNumber"
                                placeholder="699855565"
                                {...register("phoneNumber", { required: true })}
                              />
                              <span className="text-danger">
                                {errors.phoneNumber?.message}
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="email">
                                {t("email")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="input-2"
                                type="email"
                                required=""
                                name="emailaddress"
                                placeholder="stevenjob@gmail.com"
                                {...register("email", { required: true })}
                              />
                              <span className="text-danger">
                                {errors.email?.message}
                              </span>
                            </div>

                            <div className="form-group">
                              <label className="form-label" htmlFor="password">
                                {t("password")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  id="input-4"
                                  type={showPassword ? "text" : "password"}
                                  required=""
                                  name="password"
                                  placeholder="************"
                                  {...register("password", { required: true })}
                                />
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                  onClick={togglePasswordVisibility}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                  />
                                </button>
                              </div>
                              <span className="text-danger">
                                {errors.password?.message}
                              </span>
                            </div>
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="confirmPassword"
                              >
                                {t("confirmPassword")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  id="input-5"
                                  type={showConfirmPassword ? "text" : "password"}
                                  required=""
                                  name="confirmPassword"
                                  placeholder="************"
                                  {...register("confirmPassword")}
                                />
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                  onClick={toggleConfirmPasswordVisibility}
                                >
                                  <FontAwesomeIcon
                                    icon={showConfirmPassword ? faEyeSlash : faEye}
                                  />
                                </button>
                              </div>
                              <span className="text-danger">
                                {errors.confirmPassword?.message}
                              </span>
                            </div>
                            <div className="login_footer form-group d-flex justify-content-between">
                              <label className="cb-container">
                                <input type="checkbox" {...register("agree")} />
                                <span className="text-small">
                                  {t("agreeTerms")}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                              <a className="text-muted" href="#" onClick={openModal}>
                                {t("learnMore")}
                              </a>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                disabled={loading}
                              >
                                {t("submitNRegister")}
                                {loading && (
                                  <div
                                    className="spinner-border ml-10 spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </button>
                            </div>
                            <div className="text-muted text-center">
                              {t("alreadyHaveAccount")}{"  "}
                              <Link to="/login">{t("signIn")}</Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Terms and Conditions"
      >
        <h2>{t("textcon1")}</h2>
        <div>
          <h3>{t("textcon2")}</h3>
          <p>{t("textcon3")}</p>
          <h3>{t("textcon4")}</h3>
          <p>{t("textcon5")}</p>
          <h3>{t("textcon6")}</h3>
          <p>{t("textcon7")}</p>
          <h3>{t("textcon8")}</h3>
          <p>{t("textcon9")}</p>
          <h3>{t("textcon10")}</h3>
          <p>{t("textcon11")}</p>
          <h3>{t("textcon12")}</h3>
          <p>{t("textcon13")}</p>
          <h3>{t("textcon14")}</h3>
          <p>{t("textcon15")}</p>
          <h3>{t("textcon16")}</h3>
          <p>{t("textcon17")}</p>
          <h3>{t("textcon18")}</h3>
          <p>{t("textcon19")}</p>
          <h3>{t("textcon20")}</h3>
          <p>{t("textcon21")}</p>
          <h3>{t("textcon22")}</h3>
          <p>{t("textcon23")}</p>
        </div>
        <button className="btn btn-brand-1 hover-up w-100" onClick={closeModal}>{t("textconclose")}</button>
      </Modal>
    </div>
  );
};

export default Register;
