import JobCard from "../../../components/jobs/JobCard";

const JobTabContent = (props) => { 
    const {category, jobs} = props;
  return (
    <div className="row">
      {
        jobs?.map((job) => (
          <div key={job?.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
            <JobCard job={job} />
          </div>
        ))
      }
    </div>
  );
};

export default JobTabContent;