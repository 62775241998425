import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './footer.css'

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer test  py-5">
      <div className="container">
        <div className="row">
          <div className=" col-md-4 col-sm-12" style={{paddingRight: 70}}>
            {/* <div className="w-10" style={{paddingRight:70}}> */}
              <Link to="/">
                <img alt="Cible rh emploie" src="assets/imgs/cb_white.svg" />
              </Link>
              <div className="mt-20 mb-20 font-xs text-white w-20">
                {t("footerDescription")}
              </div>
              <div className="footer-social">
                <Link
                  target="_blank"
                  className="icon-socials bg-primary icon-facebook"
                  to="https://www.facebook.com/ciblerhemploi?mibextid=ZbWKwL"
                ></Link>
                <Link
                  target="_blank"
                  className="icon-socials bg-primary icon-twitter"
                  to="https://twitter.com/cible_rh_emploi?s=09"
                ></Link>
                <Link
                  target="_blank"
                  className="icon-socials bg-primary icon-linkedin"
                  to="https://www.linkedin.com/company/cible-rh-emploi/"
                ></Link>
              </div>
            {/* </div> */}
          </div>
          <div className=" col-md-4 col-xs-6">
            <h6 className="mb-20 text-white">{t("textShortcuts")}</h6>
            <ul className="menu-footer  text-white">
              <li>
                <Link to="/search" className="text-white">
                  {t("textAllJobs")}
                </Link>
              </li>
              <li>
                <Link to="https://www.ciblerh-emploi.com/contact/" 
               _target="_blank" className="text-white">
                  {t("textContact")}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className="text-white"
                  to="https://groupe-cible.com/nos-entreprises/"
                >
                  {t("textCompany")}
                </Link>
              </li>
            </ul>
          </div>
          <div className=" col-md-4 col-sm-12">
            <h6 className="mb-20 text-white">{t("footerTitleLinks")}</h6>
            <Link
              className="mr-5 "
              target="_blank"
              to="https://groupe-cible.com/"
            >
              <p className="text-white font-xs ">{t("Groupe Cible ")}</p>
            </Link>
            <Link
              className="mr-5"
              target="_blank"
              to="https://www.ciblerh-emploi.com"
            >
              <p className="text-white font-xs">{t("Cible Emploi rh")}</p>
            </Link>
            <Link
              className="mr-5"
              target="_blank"
              to="https://cabinetcible.com/"
            >
              <p className="text-white font-xs">
                {t("Cible Etudes & Conseil")}
              </p>
            </Link>
            {/* <div className="mt-15">
              <Link className="mr-5" to="#">
                <img
                  src="/assets/imgs/template/icons/app-store.png"
                  alt="joxBox"
                />
              </Link>
              <Link to="#">
                <img
                  src="/assets/imgs/template/icons/android.png"
                  alt="joxBox"
                />
              </Link>
            </div> */}
          </div>
        </div>
        <div className="row mt-4">
          <div className=" col-md-4 col-xs-6" style={{paddingRight: 70}}>
            <h6 className="mb-20 text-white">{t("footerTitleContactInfo")} Douala</h6>
            <ul className="menu-footer text-white">
              <li>
                <Link to="#" className="text-white">
                  {t("footerAddress")} : Carrefour Ancien Dalip, Immeuble
                  Amicale Chine-Cameroun Akwa - Douala (t{"textPOBox"} 3462)
                </Link>
              </li>
              <li>
                <Link to="tel:+237 233 430 677" className="text-white">
                  {t("textPhone")} : +237 6 99 90 55 58
                </Link>
              </li>
              <li>
                <Link
                  to="mailto:ciblerh@groupe-cible.com"
                  className="text-white"
                >
                  {t("textEmail")} : ciblerh@groupe-cible.com
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="hhtps://www.ciblerh-emploi.com"
                  className="text-white"
                >
                  {t("textWebsite")} : www.ciblerh-emploi.com
                </Link>
              </li>
            </ul>
          </div>

          <div className=" col-md-4 col-xs-6" style={{paddingRight: 70}}>
            <h6 className="mb-20 text-white">{t("footerTitleContactInfo")} Yaounde</h6>
            <ul className="menu-footer text-white">
              <li>
                <Link to="#" className="text-white">
                  {t("footerAddress")} : Immeuble Inter voyages
                  Rue Valery Giscard Destaing ({t("textPOBox")} 1059)
                </Link>
              </li>
              <li>
                <Link to="tel:+237 233 430 677" className="text-white">
                  {t("textPhone")} : +237 699 881 623
                </Link>
              </li>
              <li>
                <Link
                  to="mailto:ciblerh@groupe-cible.com"
                  className="text-white"
                >
                  {t("textEmail")} : ciblerh@groupe-cible.com
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="hhtps://www.ciblerh-emploi.com"
                  className="text-white"
                >
                  {t("textWebsite")} : www.ciblerh-emploi.com
                </Link>
              </li>
            </ul>
          </div>

          <div className=" col-md-4 col-xs-6" style={{paddingRight: 70}}>
            <h6 className="mb-20 text-white">{t("footerTitleContactInfo")} Kribi</h6>
            <ul className="menu-footer text-white">
              <li>
                <Link to="#" className="text-white">
                  {t("footerAddress")} : Mokolo face Commissariat Central
                  ({t("textPOBox")} 3462)
                </Link>
              </li>
              <li>
                <Link to="tel:+237 233 430 677" className="text-white">
                  {t("textPhone")} : +237 696 262 568
                </Link>
              </li>
              <li>
                <Link
                  to="mailto:ciblerh@groupe-cible.com"
                  className="text-white"
                >
                  {t("textEmail")} : ciblerh@groupe-cible.com
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="hhtps://www.ciblerh-emploi.com"
                  className="text-white"
                >
                  {t("textWebsite")} : www.ciblerh-emploi.com
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom mt-50">
          <div className="row">
            <div className="col-md-6">
              <span className="font-xs  text-white">
                Copyright &copy; {new Date().getFullYear()}. CibleRH all right
                reserved, Designed by  <Link className="color-brand-2" to="#"> WeDev Corp</Link>
              </span>
            </div>
            <div className="col-md-6 text-md-end text-start">
              <div className="footer-social">
                <Link className="font-xs text-white" to="#">
                  Privacy Policy
                </Link>
                <Link className="font-xs  text-white mr-30 ml-30" to="#">
                  Terms &amp; Conditions
                </Link>
                <Link className="font-xs  text-white" to="#">
                  Security
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
