import { useTranslation } from "react-i18next";
import TimelineCard from "./components/TimelineCard";
import { URLS } from "../../../../services/urls";
import { useEffect, useRef, useState } from "react";
import { styles } from "../../../../components/utils/reactSelect";
import { API } from "../../../../services/axios";
import { useSelector } from "react-redux";
import useSWR from "swr";
import CreatableSelect from "react-select/creatable";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmDeletion } from "../../../../components/dletion";
import Select from "react-select";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";

const ManageEducation = (props) => {
  const { resume, handleNext, handlePrev, handleRefetch } = props;
  const { t } = useTranslation();
  const [educations, setEducations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toEdit, setToEdit] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");
  const [searchDiploma, setSearchDiploma] = useState("");
  const [selected, setSelected] = useState();
  const [selectedDiploma, setSelectedDiploma] = useState();
  const { action } = useParams();

  const { data: diploma, isLoading: loadingDiploma } = useSWR(
    URLS.CONFIGS.PROFILE.DIPLOMA.list(searchDiploma)
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({});

  const formRef = useRef(null);

  const { data: colleges, isLoading: loadingColleges } = useSWR(
    URLS.CONFIGS.PROFILE.COLLEGE.list(search)
  );

  const handleChangeEducation = (education) => {
    setToEdit(education);
    reset({
      educationFrom: education?.start_date,
      currentlyWorking: !!education?.end_date === null,
      educationTo: education?.end_date,
      educationfieldOfStudy: education?.field_of_study,
      educationDegree: education?.degree,
      educationGpa: education?.gpa,
    });
    setSelected({
      label: education?.college?.name,
      value: education?.college?.id,
    });
    formRef?.current?.scrollIntoView();
  };

  const handleDeleteEducation = (education) => {
    const onSuccess = () => {
      setEducations((prev) => {
        return prev.filter((item) => item.id != education.id);
      });
    };
    confirmDeletion(
      URLS.USERS.PROFILE.EDUCATION.update(education.id),
      t,
      onSuccess
    );
  };

  const onSubmit = (data) => {
    if (!selected || !selectedDiploma) {
      toast.error(t("Degree or School is not specified, please fill them in"));
      return;
    }
    console.log(selected);
    setLoading(true);
    const obj = {
      start_date: data.educationFrom,
      end_date: data.currentlyWorking ? null : data.educationTo,
      field_of_study: data.educationfieldOfStudy,
      degree: selectedDiploma?.value,
      gpa: data.educationGpa != "" ? data.educationGpa : undefined,
      college: selected?.value,
      profile: action,
    };

    if (!toEdit) {
      API.post(URLS.USERS.PROFILE.EDUCATION.create, obj)
        .then((resp) => {
          setLoading(false);
          reset();
          toast.success(t("educationAdded"));
          setEducations((prev) => [
            {
              ...resp.data,
              college: { name: selected.label },
              degree: { name: selectedDiploma.label },
            },
            ...prev,
          ]);
          setSelected();
          setSelectedDiploma();
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    } else {
      API.patch(URLS.USERS.PROFILE.EDUCATION.update(toEdit.id), obj)
        .then((resp) => {
          setLoading(false);
          const { data } = resp;

          toast.success(t("educationEdited"));
          setEducations((prev) => {
            const eds = prev?.map((ed) => {
              if (ed.id === toEdit.id) {
                ed.start_date = data.start_date;
                ed.end_date = data.end_date;
                ed.start_date = data.start_date;
                ed.degree = data.degree;
                ed.gpa = data.gpa;
                ed.field_of_study = data.field_of_study;
                ed.college.name = selected?.label;
              }
              return ed;
            });
            return eds;
          });
          setToEdit();
          reset();
          setSelected();
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    }
  };

  const handleCreateCollege = (inputValue) => {
    setIsLoading(true);
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
    };
    API.post(URLS.CONFIGS.PROFILE.COLLEGE.create, data)
      .then((resp) => {
        const { data } = resp;
        setSelected({ label: data.name, value: data.id });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleCreateDiploma = (inputValue) => {
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
    };
    API.post(URLS.CONFIGS.PROFILE.DIPLOMA.create, data)
      .then((resp) => {
        const { data } = resp;
        setSelected({ label: data.name, value: data.id });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (resume) {
      setEducations(resume.education);
    }
  }, [resume]);

  return (
    <div className="panel-white mb-30" id="education">
      <div className="box-padding">
        <h5 className="icon-edu">{t("Education")}</h5>
        <div className="row mt-30">
          <div className="col-lg-9">
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("De")} <span className="text-primary">*</span>
                    </label>
                    <input
                      {...register("educationFrom", {
                        required: t("textFormFieldRequired"),
                      })}
                      className="form-control"
                      type="date"
                      data-date-format=""
                      placeholder="12 September 2008"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="educationFrom"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("educationTo")} <span className="text-primary">*</span>
                    </label>
                    {!watch("currentlyWorking") && (
                      <>
                        <input
                          {...register("educationTo")}
                          className="form-control"
                          type="date"
                          data-date-format="DD MMMM YYYY"
                          placeholder="12 September 2008"
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </>
                    )}
                    <div className="login_footer form-group mt-10 d-flex justify-content-between">
                      <label className="cb-container">
                        <input
                          type="checkbox"
                          {...register("currentlyWorking")}
                        />
                        <span className="text-small">
                          {t("currentlyStudying")}
                        </span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("educationDegree")}{" "}
                      <span className="text-primary">*</span>
                    </label>
                    {/* <input
                      {...register("educationDegree", { required: t("textFormFieldRequired") })}
                      className="form-control"
                      type="text"
                      placeholder={t("educationDegree")}
                    /> */}
                    <Select
                      id="college1"
                      styles={styles}
                      isDisabled={loadingDiploma}
                      inputId="select-diploma"
                      value={selectedDiploma}
                      onChange={(newValue) => setSelectedDiploma(newValue)}
                      options={
                        diploma?.results?.map((i) => {
                          return { label: i.name, value: i.id };
                        }) ?? []
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("educationGpa")} <span className="text-primary"></span>
                    </label>
                    <input
                      {...register("educationGpa")}
                      className="form-control"
                      type="number"
                      min={1}
                      placeholder={t("educationGpa")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="educationGpa"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("educationfieldOfStudy")}{" "}
                      <span className="text-primary">*</span>
                    </label>
                    <input
                      {...register("educationfieldOfStudy", {
                        required: t("textFormFieldRequired"),
                      })}
                      className="form-control"
                      type="text"
                      placeholder={t("educationfieldOfStudy")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="educationfieldOfStudy"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("Etablissement")}{" "}
                      <span className="text-primary">*</span>
                    </label>
                    <CreatableSelect
                      id="college"
                      styles={styles}
                      isSearchable
                      isDisabled={isLoading}
                      inputId="select-college"
                      value={selected}
                      isLoading={isLoading}
                      onInputChange={(value) => setSearch(value)}
                      onCreateOption={handleCreateCollege}
                      onChange={(newValue) => setSelected(newValue)}
                      options={
                        colleges?.results?.map((i) => {
                          return { label: i.name, value: i.id };
                        }) ?? []
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-10 d-flex">
                    <button className="btn btn-default btn-brand icon-tick ml-10">
                      {!toEdit ? t("textAddEducation") : t("textEditEducation")}
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm ml-10"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="box-timeline mt-50">
              {educations.map((education) => (
                <TimelineCard
                  key={education.id}
                  handleChange={handleChangeEducation}
                  handleDelete={handleDeleteEducation}
                  education={education}
                />
              ))}
            </div>
            <div className="col-lg-12 mt-3">
              <div className="form-group mt-10 d-flex">
                {educations.length >= 1 && (
                  <button
                    onClick={handlePrev}
                    className="btn btn-secondary btn-brand line-height-24"
                  >
                    {t("textPrevious")}
                  </button>
                )}

                {educations.length >= 1 && (
                  <button
                    onClick={handleNext}
                    className="btn btn-default btn-brand ml-auto"
                  >
                    {t("textNext")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEducation;
