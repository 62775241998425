import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import i18n from "../../../translations/translations";
import { changeLanguage } from "../../../store/slices/lannguage";
import { getUserDetails } from "../../utils/functions";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";




const LangSwitcher = () => {

    const { t } = useTranslation();
    const { language } = useSelector((state) => state.locale);
    const dispatch = useDispatch();

    const handleChangeLanguage = (lng) => {
        dispatch(changeLanguage(lng === "fr" ? lng : "en"))

        i18n.changeLanguage(lng === "fr" ? lng : "en");
        window.localStorage.setItem("language", lng === "fr" ? lng : "en");
        const user = getUserDetails();
        if(user) {
            API.patch(URLS.USERS.update(user?.user?.id), {"language": lng})
        }
    }

    useEffect(() => {
        const user = getUserDetails();
        if(user){
            const lng = window.localStorage.getItem("language") || user?.user?.language;
            
            dispatch(changeLanguage(lng === "fr" ? lng : "en"));
            i18n.changeLanguage(lng === "fr" ? lng : "en");
        }

    }, []);

    return (
        <div className="mr-30 my-auto ">
            <div className="dropdown text-primary">
                <button className="btn dropdown-toggle bg-transparent text-capitalize" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {/* {language === "fr" ? t("textLngFrench") : "eng"} */}
                    {language}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage("fr")}>{t("textLngFrench")}</Link>
                    <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage("eng")}>{t("textLngEnglish")}</Link>
                </div>
            </div>
        </div>
    )
}

export default LangSwitcher;