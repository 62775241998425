import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatLinkString } from "../utils/functions";
import "./styleCard.css";

const JobCardOld = (props) => {
  const { job } = props;

  const { t } = useTranslation();

  return (
    //https://gitlab.com/wedevteam1/cible_emploie_ats.git

    <div className="card-grid-2 grid-bd-16 hover-up" style={{height: "260px"}}>
      <Link to={`/job/${job.slug}/details/`}>
        <div className="card-grid-2-image">
          <span className="lbl-hot bg-green">
            <span>{job.type}</span>
          </span>

          <div className="image-box">
            <figure>
              <img
                src={
                  job?.image
                    ? formatLinkString(job?.image)
                    : "/assets/imgs/page/homepage2/logo.png"
                }
                alt="Cible rh emploie"
              />
            </figure>
          </div>
        </div>
        <div className="card-block-info">
          {job.closed && (
            <span className="lbl-hot bg-red">{t("jobExpired")}</span>
          )}
          <h5>
            <>{job.title}</>
          </h5>
          <div className="mt-5">
            <span className="card-location mr-15">
              {job.country?.name}, {job.city?.name}
            </span>
          </div>
          <span className="card-time bg-none p-0">
            {t("textNberPlaces")}: {job.required}, {t("textNberAppl")}:{" "}
            {job.submitted}
          </span>
        </div>
      </Link>
    </div>
  );
};

export default JobCardOld;
