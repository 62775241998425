import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "../../../../services/axios";
import { getUserDetails } from "../../../../components/utils/functions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { styles } from "../../../../components/utils/reactSelect";
import { confirmDeletion } from "../../../../components/dletion";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import ReactSelectCreatable from "react-select/creatable";

export const ManageIntroduction = ({ nextStep, handleRefetch }) => {
  const [loading, setLoading] = useState(false);
  const [markingAsDefault, setMarkingAsDefault] = useState(false);
  const [search, setSearch] = useState("");
  const [cv, setCV] = useState();
  const [profile, setProfile] = useState();
  const [selected, setSelected] = useState([]);
  const [selectedProfession, setProfession] = useState(null);
  const [yearExp, setYearExp] = useState(1);
  const { auth } = useSelector((state) => state.auth);
  const { data: skills, isLoading: loadingSkills } = useSWR(
    URLS.CONFIGS.PROFILE.SKILLS.list(search)
  );
  const { data: professions, isLoading: loadingProfessions } = useSWR(
    URLS.CONFIGS.PROFILE.PREFESSION.list(search)
  );

  // The url param here can be the create action or the profile id
  const { action } = useParams();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({});

  const navigate = useNavigate();

  const handleChangeCV = (event) => {
    console.log(event.target.files);
    const file = event.target.files[0];
    setCV(file);
  };

  const handleDeleteCV = (event) => {
    event.preventDefault();
    setCV();
  };

  const onSubmit = (data) => {
    console.log(selected);
    setLoading(true);
    let formData = new FormData();
    const user = getUserDetails();
    // formData.append("primary_role", data.postTitle);
    // formData.append("bio", data.userBio);
    // formData.append("salary_min", data.minSalary);
    // formData.append("salary_max", data.maxSalary);
    // formData.append("user", user.id);
    if (cv) {
      formData.append("cv", cv);
    }

    const obj = {
      primary_role: data.postTitle,
      bio: data.userBio,
      salary_min: data.minSalary,
      // salary_max: data.maxSalary,
      default: !auth?.user?.profile?.id,
      user: user?.user?.id,
      profession: selectedProfession?.value,
      skills: selected.map((skill) => skill.value),
      years_experience: data.yearExp,
    };

    if (action === "create") {
      API.post(URLS.USERS.PROFILE.create, obj)
        .then((resp) => {
          setLoading(false);
          if (cv) {
            API.patch(URLS.USERS.PROFILE.update(resp.data.id), formData);
          }
          navigate(
            `/dashboard/my-resumes/manage/${resp.data.id}?section=education&step=1`
          );
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    } else {
      API.patch(URLS.USERS.PROFILE.update(action), obj)
        .then((resp) => {
          setLoading(false);
          if (cv.type) {
            API.patch(URLS.USERS.PROFILE.update(resp.data.id), formData);
          }
          // handleRefetch()
          navigate(`/dashboard/my-resumes/manage/${resp.data.id}?step=2`);
          toast.success("introEditedSuccessfuly");
          nextStep();
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    }
  };
  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
  };

  const handleDeleteProfile = () => {
    const onSuccess = () => {
      navigate("/dashboard/my-resumes");
    };
    confirmDeletion(URLS.USERS.PROFILE.update(action), t, onSuccess);
  };

  const handleCreateSkill = (inputValue) => {
    const data = {
      name: inputValue,
      created_by: auth?.user?.id,
    };
    API.post(URLS.CONFIGS.PROFILE.SKILLS.create, data)
      .then((resp) => {
        const { data } = resp;
        setSelected((prev) => [{ label: data.name, value: data.id }, ...prev]);
      })
      .catch((error) => {});
  };

  const handleCreateProfession = (inputValue) => {
    const data = {
      name: inputValue,
      description: `Profession created by user ${auth?.user?.email}`,
    };
    API.post(URLS.CONFIGS.PROFILE.PREFESSION.create, data).then((resp) => {
      const { data } = resp;
      setProfession({ label: data.name, value: data.id });
    });
  };
  const handleMarkAsDefault = () => {
    setMarkingAsDefault(true);
    API.post(URLS.USERS.PROFILE.markAsDefault(action), {})
      .then((resp) => {
        setMarkingAsDefault(false);
        toast.success(t("textCvMarkedAsDefault"));
      })
      .catch((error) => {
        toast.success(
          error?.response?.data?.error ??
            error?.response?.data?.detail ??
            error?.response?.data?.messagerror ??
            t("textCvMarkedAsDefaultError")
        );
        setMarkingAsDefault(false);
      });
  };

  useEffect(() => {
    if (action != "create") {
      API.get(URLS.USERS.PROFILE.details(action))
        .then((resp) => {
          const { data } = resp;
          setValue("postTitle", data.primary_role);
          setValue("userBio", data.bio);
          setValue("minSalary", data.salary_min);
          // setValue("maxSalary", data.salary_max);
          setValue("yearExp", data.years_experience);

          setCV({ name: data.cv });

          setSelected(
            data?.skills?.map((i) => {
              return { label: i.name, value: i.id };
            })
          );
          setProfession({
            label: data?.profession?.name,
            id: data?.profession?.id,
          });
          setProfile(data);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
        });
    }
  }, []);

  return (
    <>
      {action != "create" && (
        <div className="panel-white mb-30">
          <div className="box-padding">
            <div className="d-flex justify-content-end">
              {!profile?.default && (
                <button
                  className="btn-primary btn"
                  onClick={handleMarkAsDefault}
                >
                  {t("markCvDefault")}
                  {markingAsDefault && (
                    <span
                      className="spinner-border spinner-border-sm ml-10"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              )}
              <button
                className="btn text-error ml-15"
                onClick={handleDeleteProfile}
              >
                {t("deleteButtonText")}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="panel-white mb-30">
        <div className="box-padding">
          <h5>{t("updateYourCV")}</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-30">
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-30">
                  <div className="box-upload">
                    <div className="add-file-upload">
                      <input
                        className="fileupload"
                        type="file"
                        name="file"
                        id="uploadCv"
                        onChange={handleChangeCV}
                      />
                    </div>
                    <label htmlFor="uploadCv" className="btn btn-default">
                      {t("uploadFile")} <span>*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                {cv && (
                  <div className="form-group mb-30 box-file-uploaded">
                    <span>{cv?.name}</span> <br />
                    <Link
                      className="btn btn-delete"
                      to="#"
                      onClick={handleDeleteCV}
                    >
                      {t("buttonDelete")}
                    </Link>
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group mb-30">
                  <label
                    className="font-sm color-text-mutted mb-10"
                    htmlFor="postTitle"
                  >
                    {t("specialities")} <span className="text-primary">*</span>
                  </label>
                  <input
                    {...register("postTitle", {
                      required: t("textFormFieldRequired"),
                    })}
                    id="postTitle"
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                  <ErrorMessage
                    errors={errors}
                    name="postTitle"
                    render={({ message }) => (
                      <span className="text-primary">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group mb-30">
                  <label
                    className="font-sm color-text-mutted mb-10"
                    htmlFor="postTitle"
                  >
                    {t("profession")} <span className="text-primary">*</span>
                  </label>
                  <CreatableSelect
                    className="basic-single"
                    options={
                      professions?.results?.map((i) => {
                        return { label: i.name, value: i.id };
                      }) ?? []
                    }
                    isClearable
                    isSearchable
                    closeMenuOnSelect={false}
                    // isMulti
                    inputId="select-profession"
                    styles={styles}
                    isLoading={loadingProfessions}
                    value={selectedProfession}
                    onCreateOption={handleCreateProfession}
                    onChange={(e) => {
                      setProfession(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-30">
                  <label className="font-sm color-text-mutted mb-10">
                    {t("yearOfExperience")}
                  </label>
                  <input
                    {...register("yearExp", {
                      required: t("textFormFieldRequired"),
                    })}
                    onChange={(e) => {
                      setYearExp(e.target.value);
                    }}
                    className="form-control"
                    type="text"
                    placeholder="12"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group mb-30">
                  <label className="font-sm color-text-mutted mb-10">
                    {t("profileSkills")} <span className="text-primary">*</span>
                  </label>
                  <CreatableSelect
                    options={
                      skills?.results?.map((i) => {
                        return { label: i.name, value: i.id };
                      }) ?? []
                    }
                    isClearable
                    isSearchable
                    closeMenuOnSelect={false}
                    isMulti
                    inputId="select-skills"
                    styles={styles}
                    isLoading={loadingSkills}
                    value={selected}
                    onCreateOption={handleCreateSkill}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-30">
                  <label className="font-sm color-text-mutted mb-10">
                    {t("minSalary")} <span className="text-primary">*</span>
                  </label>
                  <input
                    className="form-control"
                    {...register("minSalary", {
                      required: t("textFormFieldRequired"),
                    })}
                    type="number"
                    placeholder=""
                  />
                  <ErrorMessage
                    errors={errors}
                    name="minSalary"
                    render={({ message }) => (
                      <span className="text-primary">{message}</span>
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-30">
                  <label className="font-sm color-text-mutted mb-10">
                    {t("userBio")} <span className="text-primary">*</span>
                  </label>
                  <textarea
                    {...register("userBio", {
                      required: t("textFormFieldRequired"),
                    })}
                    rows={4}
                    className="form-control"
                    type="text"
                    placeholder=""
                  />
                  <ErrorMessage
                    errors={errors}
                    name="userBio"
                    render={({ message }) => (
                      <span className="text-primary">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="d-flex">
                  <button className="btn btn-default btn-brand" type="submit">
                    {action === "create" ? t("btnContinue") : t("btnUpdate")}{" "}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm ml-10"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                  {action != "create" && (
                    <button
                      onClick={nextStep}
                      className="btn btn-default btn-brand ml-auto"
                      type="button"
                    >
                      {t("textNext")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ManageIntroduction;
