import Footer from "../footer";
import Header from "../header";
import {Outlet} from "react-router-dom"
import AuthHeader from "../header/AuthHeader";
import { useSelector } from "react-redux";


const HomeLayout = () => {

    const {auth} = useSelector((state) => state.auth)

    return(
        <>
        {!auth ? <Header /> :
        <AuthHeader />}
        <Outlet />
        <Footer />
        </>
    )
}

export default HomeLayout;