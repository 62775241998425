import { Outlet } from "react-router-dom";
import AuthHeader from "../header/AuthHeader";
import NavBar from "../navBar";
import PrivateRoute from "../../routes/components/PrivateRoute";

const UserDashboardLayout = () => {
  return (
    <PrivateRoute>
      <AuthHeader />
      <div className="user-dash">
        <main className="main dashboard">
          <NavBar />
          <Outlet />
        </main>
        {/* <Footer /> */}
      </div>
    </PrivateRoute>
  );
};

export default UserDashboardLayout;
