import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { URLS } from "../../../services/urls";
import "./home.css";

const JobsByLocation = ({ locations }) => {
  const [lastImage, setLastImage] = useState();

  const { t } = useTranslation();

  const { data: cals, isLoadingCal } = useSWR(URLS.CAROUSSEL.list());

  useEffect(() => {
    if (cals) {
      const userImages = cals.results.filter(
        (item) => item.image_list.step === "country"
      );
      const lastImage = userImages[userImages.length - 1];
      setLastImage(lastImage);
    }
  }, [cals]);
  console.log(cals);

  return (
    <section className="section-box mt-50">
      <div className="container">
        <div className="text-start">
          <h2 className="section-title text mb-10 wow animate__animated animate__fadeInUp">
            {t("textByLocation")}
          </h2>
          <p className="font-lg text-danger color-text-paragraph-2 wow animate__animated animate__fadeInUp">
            {t("textByLocationText")}
          </p>
        </div>
        <div className="container">
          <div className="row mt-50">
            {_.orderBy(
              locations, // Data to be sorted
              [
                ({ job_offers: { length } }) => length, // First, sort by length
                "job_offers", // Them sort lexicographically
              ],
              [
                "desc", // Length (descending)
              ]
            )
              ?.slice(0, 8)
              .map((location, index) => {
                return (
                  <div
                    key={"pop-loc-" + location.id}
                    className={`col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12`}
                  >
                    <div className="card-image-top local hover-up">
                      <Link to={`/search?location=${location?.name ?? ""}`}>
                        <div className=" text-end">
                          <img
                            src="assets\imgs\page\job-single/location.svg"
                            alt="Cible rh emploie"
                          />
                        </div>

                        <div className="informations">
                          <Link to={`/search?location=${location?.name ?? ""}`}>
                            <h5>{location.name}</h5>
                          </Link>
                          <div className="row">
                            <div className="col-lg-6 col-6">
                              <span className="text-14 color-text-paragraph-2">
                                {location?.job_offers?.length}{" "}
                                {t("textVacancy")}
                              </span>
                            </div>
                            {/* <div className="col-lg-6 col-6 text-end"><span className="color-text-paragraph-2 text-14">120 companies</span></div> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobsByLocation;
