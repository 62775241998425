import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { setUserDetails } from "../../components/utils/functions";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/slices/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    setLoading(true);
    API.post(URLS.AUTHS.LOGIN, data)
      .then((resp) => {
        const redirect = window.localStorage.getItem("redirect");
        const redirect_link = redirect?.includes('login') ? "/dashboard" : redirect;
        setLoading(false);
        let usrData = resp.data;
        usrData.user.id = usrData.user.pk;
        setUserDetails(usrData);
        dispatch(loginSuccess(usrData));
        navigate(searchParams.get("redirect") ?? redirect_link ?? "/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.detail ??
          error?.response?.data?.messagerror ??
          t("textLoginError")
        );
      });
  };

  return (
    <div className="box-content">
      <div className="banner-hero hero-2">
        <div className="banner-inner"></div>
      </div>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card rounded border shadow-lg p-4 bg-transparent">
                <div className="card-body p-5 text-center">
                  <div className="text-center">
                    <img
                      src="assets/imgs/page/homepage2/logo.png"
                      style={{ width: "185px" }}
                      alt="logo"
                    />
                  </div>
                  <form
                    className="login-register text-start mt-20"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-1">
                        {t("email")} *
                      </label>
                      <input
                        className="form-control"
                        id="input-1"
                        type="email"
                        required=""
                        {...register("email", {
                          required: t("textFormFieldRequired"),
                          validate: {
                            maxLength: (v) => v.length <= 50 || t("textAtMost"),
                            matchPattern: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || t("textValidEmail"),
                          },
                        })}
                        placeholder="stevenjob@gmail.com"
                      />
                      <span className="text-primary">
                        {errors.email?.message && (
                          <small>{errors.email.message}</small>
                        )}
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-4">
                        {t("password")} *
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          id="input-4"
                          type={showPassword ? "text" : "password"}
                          {...register("password")}
                          required=""
                          placeholder="************"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                      </div>
                    </div>
                    <div className="login_footer form-group d-flex justify-content-between">
                      <Link className="text-muted" to="/password-reset">
                        {t("forgotPassword")}
                      </Link>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary gradient-custom-2 hover-up w-100"
                        type="submit"
                        name="login"
                        disabled={loading}
                      >
                        {t("actionLogin")}
                        {loading && (
                          <div
                            className="spinner-border ml-10 spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                    <div className="text-muted text-center">
                      {t("dontHaveAccount")}{" "}
                      <Link to="/register">{t("signUp")}</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;