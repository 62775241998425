import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const EmailVerification = () => {
  const { emailKey } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleResendVerification = () => {
    if (email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setLoading(true);
      API.post(URLS.AUTHS.VERIFY.resend, {email: email}).then((resp) => {
        toast.success(t("textKeyResend"));
        setLoading(false);
      }).catch((error) => {
        toast.error(
          error?.response?.data?.detail ?? error?.response?.data?.messagerror ?? t("emailRevalidationError")
        );
      })
    } else {
      toast.error(t("textCheckEmail"));
    }
  };

  useEffect(() => {
    API.post(URLS.AUTHS.VERIFY.email, { key: emailKey })
      .then((resp) => {
        toast.success(t("textEmailVerified"));
        navigate("/login");
      })
      .catch((error) => {
        if (error?.response?.status) {
          toast.error(t("textEmailVerificationKeyNotFound"));
          setNotFound(true);
        } else {
          toast.error(
            error?.response?.data?.detail ?? error?.response?.data?.messagerror ?? t("emailValidationError")
          );
        }
      });
  }, []);

  return !notFound ? (
    <div className="d-flex mt-50" style={{ height: "90vh" }}>
      <div
        className="spinner-border m-auto"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div className="box-content">
      <div className="box-heading"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30 mt-100">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-3">
                      <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                            <p className="font-sm text-brand-2">
                              {t("textResendVerification")}{" "}
                            </p>
                          </div>
                          <form className="login-register text-start mt-20">
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-1">
                                {t("email")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-1"
                                type="email"
                                required
                                placeholder="stevenjob@gmail.com"
                                onChange={(event) => setEmail(event.target.value)}
                              />
                            </div>

                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="button"
                                name="login"
                                disabled={loading}
                                onClick={handleResendVerification}
                              >
                                {t("textResendVerificationAction")}
                                {loading && (
                                  <div
                                    className="spinner-border ml-10 spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </button>
                            </div>
                            <div className="text-muted text-center">
                              <Link to="/login">{t("actionLogin")}</Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
