export const fr = {
  passSpecialChar: "Le mot de passe doit contenir au moins un caractère spécial (par exemple: @, #, $, %, &, *).",
  passNumber: "Le mot de passe doit contenir au moins un chiffre.",
  passLowerCase: "Le mot de passe doit contenir au moins une lettre minuscule.",
  passUpperCase: "Le mot de passe doit contenir au moins une lettre majuscule.",
  textPwMinLength: "Au moins 8 caractères.",
  textPwPattern: "Doit contenir au moins une lettre majuscule, un chiffre, et un caractère spécial.",
  textcon1: "Conditions Générales d'Utilisation",
  textcon2: "Conditions d'utilisation",
  textcon3: "Bienvenue sur notre site de recrutement en ligne. En accédant et en utilisant notre site, vous acceptez de respecter et d'être lié par les conditions générales suivantes. Veuillez les lire attentivement. Si vous n'acceptez pas ces conditions générales, vous ne devez pas utiliser notre site.",
  textcon4: "1. Acceptation des Conditions",
  textcon5: "En utilisant notre site, vous reconnaissez avoir lu, compris et accepté d'être lié par ces conditions générales, ainsi que par toutes les lois et réglementations applicables. Ces conditions générales constituent un accord contraignant entre vous et notre société.",
  textcon6: "2. Utilisation du Site",
  textcon7: "Vous pouvez utiliser notre site à des fins légales et conformément à ces conditions générales. Vous acceptez de ne pas utiliser notre site à des fins illégales ou non autorisées, y compris mais sans s'y limiter, la violation de tout droit de propriété intellectuelle ou de confidentialité.",
  textcon8: "3. Comptes Utilisateurs",
  textcon9: "Pour accéder à certaines fonctionnalités de notre site, vous pouvez être amené à créer un compte utilisateur. Vous acceptez de fournir des informations exactes et complètes lors de la création de votre compte et de maintenir vos informations à jour. Vous êtes responsable de la confidentialité de vos identifiants de compte et de toutes les activités qui se produisent sous votre compte.",
  textcon10: "4. Annonces d'Emploi",
  textcon11: "Notre site peut contenir des annonces d'emploi publiées par des employeurs tiers. Nous ne cautionnons ni ne garantissons l'exactitude de toute annonce d'emploi et nous ne sommes pas responsables de toute opportunité d'emploi ou relation de travail pouvant résulter de votre utilisation de notre site. Vous reconnaissez utiliser notre site et vous fier aux annonces d'emploi à vos propres risques.",
  textcon12: "5. Politique de Confidentialité",
  textcon13: "Votre utilisation de notre site est également régie par notre Politique de Confidentialité. En utilisant notre site, vous consentez à la collecte, l'utilisation et la divulgation de vos informations personnelles comme décrit dans notre Politique de Confidentialité.",
  textcon14: "6. Propriété Intellectuelle",
  textcon15: "Tout le contenu de notre site, y compris mais sans s'y limiter, les textes, graphiques, logos, images et logiciels, est la propriété de notre société ou de nos concédants de licence et est protégé par des droits d'auteur, des marques et d'autres lois sur la propriété intellectuelle. Vous acceptez de ne pas reproduire, distribuer, modifier ou créer des œuvres dérivées de tout contenu de notre site sans notre consentement écrit préalable.",
  textcon16: "7. Limitation de Responsabilité",
  textcon17: "Dans toute la mesure permise par la loi applicable, notre société et ses dirigeants, administrateurs, employés et agents ne seront pas responsables de tout dommage indirect, accessoire, spécial, consécutif ou punitif résultant de ou en rapport avec votre utilisation de notre site, y compris mais sans s'y limiter, les dommages pour perte de profits, de données ou autres pertes intangibles.",
  textcon18: "8. Droit Applicable",
  textcon19: "Ces conditions générales seront régies et interprétées conformément aux lois de la juridiction dans laquelle se trouve notre société, sans égard à ses principes de conflit de lois. Toute action en justice ou procédure découlant de ou liée à ces conditions générales devra être portée exclusivement devant les tribunaux de cette juridiction.",
  textcon20: "9. Modifications des Conditions Générales",
  textcon21: "Nous nous réservons le droit de modifier ou de mettre à jour ces conditions générales à tout moment sans préavis. Votre utilisation continue de notre site après de telles modifications constitue votre acceptation des nouvelles conditions générales. Nous vous encourageons à revoir périodiquement ces conditions générales.",
  textcon22: "10. Nous Contacter",
  textcon23: "Si vous avez des questions ou des préoccupations concernant ces conditions générales, veuillez nous contacter en utilisant les informations de contact fournies sur notre site.",
  textconclose: "Fermer",
  textCompany: "Entreprises",
  textpop: "Recherches populaires",
  textThankForSubscribing: "Merci d'avoir souscrit à notre NewsLetters",
  textv: `Une <span class="text-bold2">vision</span> claire`,
  textm: `Une <span class="text-bold2">mission</span> possible`,
  texts: "Qui sommes nous",
  textvision:
    "Être l’entreprise N°1 en matière de Gestion des Ressources Humaines",
  textvision2:
    "partout en Afrique centrale et en Afrique Subsaharienne.",
  textmission1:
    "Apporter des solutions pratiques pour guider et accompagner les organisations, les entreprises publiques et privées, ainsi que les particuliers dans le cadre de la gestion du capital humain.",
  textmission2:
    "les entreprises publiques et privées, ainsi que les particuliers dans",
  textmission3: "le cadre du développement de leurs activités et compétences.",
  textaboutf:
    `Nous sommes <span class="text-bold">Cible RH Emploi</span>, entreprise leader dans le domaine du conseil et des prestations RH. Depuis plus de vingt ans, nous mettons du personnel de qualité à votre disposition au Cameroun et en Afrique Centrale. N’ayant pour ambition que votre succès, nous combinons une vocation africaine avec des standards internationaux.`,
  textaboutT:
    "Cible RH Emploi propose une large gamme de services pour vous aider à optimiser la gestion de vos ressources sur le long terme",
  textaboutr:
    "Aujourd'hui, depuis notre siège à Douala, 42 collaborateurs travaillent sans relâche pour répondre à vos besoins et vous aider à réaliser vos ambitions",
  findJob: "Rechercher et trouver le travail de ses rêves",
  dayJob: "Offres du jour",
  logtext: "Le conseiller d'emploi n°1 pour trouver l'emploi qui vous convient",
  textWho: "Qui Somme Nous",
  AllJobs: "Toutes les offres",
  jobExpired: "Fermé",
  btnContinue: "continuer",
  experienceCompany: "experience",
  textDescrption: "description",
  passMustMatch: "Les mots de passe doivent correspondre",
  requis: "Requis",
  noPassProvided: "Aucun mot de passe fourni.",
  passToShort:
    "Le mot de passe est trop court - doit être de 8 caractères minimum.",
  textShortcuts: "Raccourcis",
  passOnlyLatinLetters:
    "Le mot de passe ne peut contenir que des lettres latines.",
  startForFree: "Démarrez gratuitement aujourd'hui",
  acessAllFeatures:
    "Accès à toutes les fonctionnalités. Aucune carte de crédit requise.",
  firstName: "Prénom",
  lastName: "Nom de famille",
  email: "Email",
  password: "Mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  agreeTerms: "Accepter nos conditions et notre politique",
  learnMore: "En savoir plus",
  submitNRegister: "Soumettre et s'inscrire",
  alreadyHaveAccount: "Vous avez déjà un compte ?",
  signIn: "Connexion",
  rememberMe: "Se souvenir de moi",
  forgotPassword: "Mot de passe oublié",
  actionLogin: "Connexion",
  dontHaveAccount: "Vous n'avez pas de compte ?",
  signUp: "S'inscrire",
  phoneNumber: "Numéro de téléphone",
  emailValidationError: "Erreur de validation de l'e-mail ;",
  myProfiles: "Mes CVs",
  menu_user: "Utilisateur",
  addNew: "Ajouter",
  updateYourCV: "Mettre à jour votre CV",
  uploadFile: "Télécharger le fichier",
  boutonSupprimer: "Supprimer",
  postTitle: "Titre de l'offre",
  minSalary: "Salaire minimum",
  maxSalary: "Salaire maximum",
  userBio: "Bio",
  btnContinuer: "Continuer",
  profileSkills: "Compétences du profil",
  btnUpdate: "Mettre à jour",
  titleEducation: "Éducation",
  educationDe: "De",
  educationTo: "À",
  educationDegree: "Diplôme",
  educationSchool: "École",
  educationGpa: "Note",
  educationfieldOfStudy: "Domaine d'études",
  educationCollege: "Collège",
  currentlyWorking: "Je travaille actuellement ici",
  currentlyStudying: "Étudie actuellement ici",
  currentlyShort: "Actuel",
  addTimeline: "Ajouter une chronologie",
  educationAdded: "L'éducation a été ajoutée avec succès à votre profil",
  menuHome: "Accueil",
  myResume: "Mon CV",
  editTimeline: "Modifier la chronologie",
  educationEdited: "Éducation modifiée avec succès",
  deleteAreYouSure: "Êtes-vous sûr ?",
  deleteIrreversibleAlert: "Vous ne pourrez pas revenir en arrière !",
  deleteActionButton: "Oui, supprimez-le !",
  deletionDeleted: "Supprimé !",
  deleteSuccessMessage: "Votre opération de suppression a réussi.",
  deleteActionCancel: "Annuler",
  deleteProcess: "Suppression en cours",
  requestGlobalErrorMessage: "Quelque chose s'est mal passé",
  experienceSociété: "Société",
  textDescription: "Description",
  workExperience: "Travail & Expérience",
  experienceAdded: "Expérience ajoutée avec succès sur votre profil",
  experienceEdited: "Expérience modifiée avec succès",
  experienceSector: "Secteur d'activité",
  markCvDefault: "Marquer le CV par défaut",
  deleteButtonText: "Supprimer",
  introEditedSuccessfuly: "Profil modifié avec succès",
  textDefault: "Par défaut",
  jobsOfTheDay: "Les métiers du jour",
  searchAndConnect: "Cherchez et décrochez l'emploi de vos rêves.",
  textSearch: "Rechercher",
  postAJob: "Publier une offre d'emploi",
  textAdmin: "Administrateur",
  postNewJob: "Publier un nouvel emploi",
  jobInformation: "Informations sur l'emploi",
  jobTitle: "Intitulé du poste",
  JobType: "Type",
  WorkplaceType: "Type de lieu de travail",
  workingTypePart: "Temps partiel",
  workingTypeFull: "Temps plein",
  workingTypeRemote: "A distance",
  workingTypeFreelancer: "Freelancer",
  jobExperienceYear: "Nombre d'années d'expérience",
  jobExperienceLevel: "Niveau d'expérience",
  jobExpEntry: "Débutant",
  jobExpInterm: "Intermédiaire",
  jobExpSenior: "Senior",
  jobExpert: "Expert",
  jobMinSalary: "Salaire minimum",
  jobMaxSalary: "Salaire max.",
  jobWorkPlaceOnsite: "Sur site",
  jobWorkPlaceRemote: "A distance",
  jobWorkPlaceHybrid: "Hybride",
  jobApplicationEnd: "Fin de la candidature",
  jobVisaSponsored: "Visa sponsorisé",
  jobDescription: "Description",
  jobResponsibilities: "Responsabilités du poste",
  jobSpecialNote: "Note de travail spéciale pour l'utilisateur",
  jobIndustrie: "Industrie",
  jobCompany: "Recrutement en entreprise",
  jobSkillss: "Compétences",
  textJobs: "Offres",
  jobCountry: "Pays",
  jobCity: "Ville",
  jobProcess: "Processus de recrutement (séparés par des virgules)",
  jobIncludePertners: "Inclure les partenaires",
  textJobDetails: "Détails de l'offre",
  textJobApplications: "Demandes d'emploi",
  textCvTheque: "Cv Thèque",
  textProfileDetails: "Détails du profil",
  textDownloadCv: "Télécharger le CV",
  textProfileSteps: "Étapes du profil",
  textShortBio: "Courte biographie",
  textSkills: "Compétences",
  textAboutMe: "À propos de moi",
  textOverview: "Aperçu",
  textExpectedSalary: "Salaire attendu",
  textLinkedIn: "LinkedIn",
  textPhone: "Téléphone",
  textEmail: "E-mail",
  buttonSendMessage: "Envoyer le message",
  textMyProfile: "Mon Profil",
  textUpdateProfile: "Mettre à jour votre profil",
  textUploadAvatar: "Télécharger l'avatar",
  textFirstName: "Prénom",
  textLastName: "Nom de famille",
  textPhoneNumber: "Numéro de téléphone",
  textLanguage: "Langue",
  buttonSaveChange: "Enregistrer la modification",
  textContactInformation: "Coordonnées",
  textLngFrench: "Français",
  textLngEnglish: "Anglais",
  textSocialNetwork: "Réseau social",
  textTwitter: "Twitter",
  textWebsite: "Site Web",
  textLinkedin: "Linkedin",
  textTwitter: "Twitter",
  textPerMonth: "Mois",
  textEmploymentInfo: "Informations sur l'emploi",
  textIndustry: "Secteur",
  textJobLevel: "Niveau de l'offre",
  textSalary: "Salaire",
  textExperience: "Expérience",
  textYears: "Années",
  textJobType: "Type d'offre",
  textCreated: "Créé",
  textDeadline: "Date limite",
  textLocation: "Localisation",
  textApplyNow: "Postuler maintenant",
  textShareJob: "Partager",
  textSimilarJobs: "Emplois similaires",
  footerDescription:
    "Cible Rh emploi est  leader dans le domaine du conseil et des prestations RH",
  footerTitleContactInfo: "Coordonnées",
  footerAddress: "Adresse",
  footerTitleLinks: "Liens",
  textAllJobs: "Toutes les offres",
  textContact: "Contact",
  footerTitleNewsletter: "Newsletter",
  footerTextNewslettet:
    "Inscrivez-vous à notre newsletter pour recevoir toutes nos offres en continu.",
  textJObsAvailable: "Emplois disponibles",
  textFindTheJOb: "Trouvez l'emploi qui vous convient",
  textJobBoard: `
     Le <span class="text-primary">conseiller n°1 pour</span>
                 <br class="d-none d-lg-block" />
                 trouver l'emploi qui vous convient`,
  textYourKeyword: "Votre mot-clé...",
  textInscriptionCreateAccount: "S'inscrire et créer des profils",
  textInscriptionCreateAccountText:
    "Est-ce votre première visite sur notre site ? Si oui, nous vous invitons à créer un compte et à compléter votre profil pour terminer le processus de candidature.",
  textSearchJob: "Rechercher un emploi",
  textSearchJobText:
    "Entrez un intitulé de poste ou un mot-clé, Entrez un emplacement Cliquez sur le bouton Rechercher un emploi.",
  textHowToApply: "Comment postuler ?",
  textHowToApplyText:
    "Une fois que vous avez trouvé une offre qui vous corresponde, vous avez deux choix : postuler via votre profil, ou via votre CV",
  textSeeSomeWords: "Voir quelques mots",
  textThousand: "Des milliers d'employés obtiennent leur emploi idéal",
  andFeedBack: "et faites-nous part de vos commentaires !",
  jobApplicationSuccess: "Candidature soumise avec succès.",
  textNewProfile: "Nouveau profil",
  myApplications: "Mes candidatures",
  textAppliedOn: "Postulé le",
  textLoginingOut: "Déconnexion...",
  textDashboard: "Tableau de bord",
  textApplications: "Candidatures",
  textMyProfile: "Mon profil",
  cvManage: "Gérer mes CV",
  textLogout: "Déconnexion",
  textAdvanceFilter: "Filtre avancé",
  textReset: "Réinitialiser",
  textDescription: "Description",
  tetSkills: "Compétences",
  textResponsabilities: "Responsabilités",
  textProfileEditedSuccess: "Profil modifié avec succès",
  textApplicationStatus: "Statut de la candidature",
  text_sent: "Envoyé",
  text_in_progress: "En cours",
  text_interview: "Entretien",
  text_test: "En test",
  text_hr: "RH",
  text_human_ressources: "Ressources humaines",
  text_rejected: "Refusé",
  text_validated: "Validé",
  text_waiting_user: "En attente de votre confirmation",
  text_accepted: "Accepté",
  text_canceled: "Annulé",
  textHome: "Accueil",
  textAvailableNow: "Disponibles maintenant",
  textGithub: "Github",
  textMadeBy: "Fait par",
  textUser: "Utilisateur",
  textFindJob: "Trouver un emploi",
  textAboutUs: "A propos",
  textYourAccount: "Votre compte",
  textLogin: "Connexion",
  textRegister: "S'inscrire",
  textSignIn: "Connexion",
  textNoJob: "Aucune offre d'emploi trouvée",
  textLoginError:
    "Impossible de se connecter avec les informations d'identification fournies.",
  textWelcomeBack: "Bienvenue !",
  textAtMost: "L'e-mail doit comporter au maximum 50 caractères",
  textValidEmail: "L'adresse e-mail doit être une adresse valide",
  textEmailNotFound: "E-mail introuvable",
  textResetPw: "Réinitialiser le mot de passe",
  textRecoverPwEmailSend:
    "L'e-mail de réinitialisation du mot de passe a été envoyé.",
  textConfirmError:
    "La confirmation de la réinitialisation du mot de passe a échoué",
  textPwMustMatch: "Vos mots de passe ne correspondent pas",
  textSetNewPassword: "Définir un nouveau mot de passe",
  textNoNotifs: "Aucune notification trouvée",
  textNotifications: "Notifications",
  textTitle: "Titre",
  textContent: "Contenu",
  textNotificationsDetails: "Détails de la notification",
  textNotifDetailsError:
    "Désolé, nous ne pouvons pas afficher cette notification, veuillez réessayer plus tard",
  textProfileEditedError:
    "Désolé, nous ne pouvons pas modifier le profil et une erreur s'est produite",
  textEmailVerified: "E-mail vérifié avec succès",
  textResendVerificationAction: "Renvoyer",
  textResendVerification: "Renvoyer le code de vérification",
  textCheckEmail: "Vérifier l'e-mail et réessayer",
  textKeyResend:
    "Le code de vérification a été renvoyé. Vérifiez votre boîte aux lettres !",
  textkeyword: "Mots clés",
  emailRevalidationError:
    "Nous ne pouvons pas envoyer le code de revalidation, veuillez vérifier votre adresse e-mail et réessayer.",
  textEmailVerificationKeyNotFound:
    "Clé de vérification de l'e-mail introuvable. Essayez de vous connecter ou renvoyez le lien de vérification.",
  textNberPlaces: "Postes ouverts",
  textNberAppl: "Candidatures",
  textGender: "Sexe",
  textAge: "Âge",
  textChoose: "Choisissez une option",
  textMale: "Homme",
  textFemale: "Femme",
  textMale: "Homme",
  textNewsWill: `Des mises à jour seront faites régulièrement`,
  textSubscribe: "S'abonner",
  textEnterEmail: "Entrez votre email ici",
  textThankForSubscribe: "Merci de vous être abonné à notre newsletter.",
  textNewsletterFailed: "Votre abonnement a échoué.",
  textOurProcess: "Notre processus",
  textAppliedJobs: "Candidatures",
  textJobsCount: "Total d'offres",
  textJobFound: "Emploi trouvé",
  textProfiles: "Profils",
  textRecommendedJobs: "Offres recommandées",
  textTopRecruitment: "Top recrutement",
  textApplicationDetails: "Détails de la candidature",
  textHistory: "Historique",
  textApplicationInformations: "Informations sur la candidature",
  textAppliedOn: "Postulé le",
  textIsActive: "Est actif",
  textYes: "Oui",
  textNo: "Non",
  textStep: "Étape",
  textJob: "Offre",
  textPreviousStep: "Étape précédente",
  textApplicationNotFound: "Candidature introuvable",
  textActions: "Actions",
  textCancelApplication: "Annuler ma candidature",
  textAcceptJob: "Accepter l'offre",
  textYesCancel: "Oui, annuler",
  textApplicationCancelError:
    "Une erreur s'est produite lors de l'annulation de votre candidature",
  textApplicationCancelled: "Demande d'emploi annulée !",
  textApplicationAccepted: "Candidature acceptée",
  textApplicationAcceptationError: "L'acceptation de l'offre a échoué",
  textIntroduction: "Introduction",
  textCertifications: "Certifications",
  textLanguages: "Langues",
  textReferences: "Références",
  textAddEducation: "Ajouter une formation",
  textEditEducation: "Modifier l'éducation",
  textPrevious: "Précédent",
  textNext: "Suivant",
  textAddExperience: "Ajouter une expérience",
  textEditExperience: "Modifier l'expérience",
  textCertifName: "Nom de la certification",
  textDate: "Date",
  textCertifAdded: "Certification ajoutée avec succès",
  textCertifAddingError: "Erreur de création de la certification !",
  textAddCertification: "Ajouter une certification",
  textEditCertification: "Modifier la certification",
  textJoinedFile: "Fichiers joints",
  textLangName: "Nom de la langue",
  textLevel: "Niveau",
  textAddLanguage: "Ajouter une langue",
  textEditLanguage: "Modifier la langue",
  textLanguageAddingError: "Erreur d'ajout de langue !",
  languageEdited: "Langue modifiée avec succès",
  textLanguageAdded: "Langue ajoutée avec succès",
  textName: "Nom",
  textAddReference: "Ajouter une référence",
  textEditReference: "Modifier la référence",
  textReferenceAddingError: "Erreur d'ajout de référence !",
  referenceEdited: "Référence modifiée avec succès",
  textReferenceAdded: "Référence ajoutée avec succès",
  textViewResumes: "Afficher mes CV",
  textApplication: "Candidature d'emploi",
  textStartUrCareer: "Commencez votre carrière aujourd'hui",
  textChooseProfile:
    "Veuillez choisir votre profil et l'envoyer à l'employeur.",
  textFalse: "Faux",
  textTrue: "Vrai",
  textAdditionalInformations: "Informations supplémentaires",
  textFormFieldRequired: "Ce champ est obligatoire",
  textDateObtained: "Date d'obtention",
  textSubmit: "Soumettre",
  textBeginner: "Débutant",
  textIntermediate: "Intermédiaire",
  textAdvanced: "Avancé",
  textPopCat: "Catégorie populaire",
  textPopCatText: "Connectez-vous plus rapidement avec les bons candidats.",
  textByLocation: "Emplois par emplacement",
  textByLocationText:
    " Trouvez vos emplois préférés et bénéficiez de nos avantages",
  textRole: "Rôle",
  textBestRecruiting: "Les secteurs qui recrutent le plus.",
  textVacancy: "Offre",
  textVacancys: "Offres",
  textRequiredField: "Ce champs est requis",
  profession: "Métier",
  yearOfExperience: "Années d'Experience",
  textAll: "Tous",
  textMoreDetails: "En savoir plus",

  textDesigner:"Designeur",
  textDeveloper:"Developpeur",
  textEngineer:"Engenieur",
  textPOBox: "B.P.",
  textLinkSent: "Un email a été envoyé à votre adresse. Cliquez sur le lien Pour valider votre compter",
  textSignupSuccess: "Inscription Réussie",
  textSignupSuccessMsg: "Vous avez reçu un lien dans votre boîte email. Cliquez sur le lien pour valider votre compte et commencez l'aventure"
};
